import {React,useState,useEffect} from "react";
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

const ROLES = [
    {rolename:"FRONTDESK"},
    {rolename:"MANAGER"},
    {rolename:"SUPERVISOR"},
    {rolename:"LEAD"}
]

function User() {
    const navigate = useNavigate ();
    const [name,setName] = useState('');
    const [role,setRole] = useState('');
    const [password,setPassword] = useState('');
    const [roles,setRoles] = useState(ROLES);
    let loc = useLocation();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const save = (e)=>{
        if(role.trim()=="" || name.trim()=="" || password.trim()==""){
            alert("Name, password and roles must be specified");
        }
        else{
            e.preventDefault();
            e.currentTarget.disabled = true;
            switch (loc.state.mode) {
                case "NEW":
                    const data = {role:role,name:name,password:password};
                    api.post("/users",data,{headers:head});
                    break;
                case "EDIT":
                    const editData = {name:name,password:password,id:loc.state.record.id};
                    api.put("/users",editData,{headers:head});
                    break;

                default:
                    break;
            }
            
        }
        
    }

    useEffect(()=>{
        if(loc.state.mode == "EDIT"){
            setName(loc.state.record.name);
            setRole(loc.state.record.role);
        }
    },[])


    return (
        <>
        <h5>{loc.state.mode} User Details</h5>
        <form>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Name</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-user"></i></div>
                        <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="form-control" placeholder="User Name"></input>
                    </div>
                </div>
                {
                    loc.state.mode=="NEW" ? 
                    <div className="col-auto">
                    <label className="visually-hidden" >Role</label>
                    <select className="form-select" value={role} onChange={(e)=>{setRole(e.target.value)}}>
                        <option value={''} key={-1} selected>Choose...</option>
                        {
                            roles.map((role,i)=>(
                                <option value={role.rolename} key={i}>{role.rolename}</option>
                            ))
                        }
                    </select>
                    </div> : ""

                }
                
                <div className="col-auto">
                    <label className="visually-hidden" >Password</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-key"></i></div>
                        <input type="password" value={password} onChange={(e)=>{setPassword(e.target.value)}} className="form-control" placeholder="Password"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col">
                    <button className="btn btn-sm btn-primary me-2" onClick={save}>Save</button>
                    <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate('..')}}>Close</button>
                </div>
            </div>  
        </form>
        </>

    );
}
export default User;