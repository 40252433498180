import React, { useState,useEffect } from 'react';
import { useNavigate,createSearchParams  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function VacancySearch(props) {
    const navigate = useNavigate ();
    const [name,setName]= useState('');
    const [jobtitle,setJobtitle] = useState('');
    const [education,setEducation] = useState('');
    const userRole = sessionStorage.getItem('portalhr.role');
    const [clients,setClients] = useState([]);

    const vacancySearch =(e)=>{
        e.preventDefault();
        navigate({pathname :"search",
                  search: createSearchParams({name:name,jobtitle:jobtitle,education:education}).toString()
                })
    }

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/institutions";
                var dt = await api.get(url,{headers:head});
                setClients(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <>
            <div className="row">
                <div className="col-auto">
                    <label className="visually-hidden">Name</label>
                    <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-building"></i></div>
                            
                            <select className='form-select' value={name} onChange={(e)=>{setName(e.target.value)}}>
                                <option value={""}>  </option>
                                {
                                    clients.map((client,i)=>(
                                        <option value={client.name} key={i}>{client.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Jobtitle</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-id-card"></i></div>
                        <input type="text" className="form-control" value={jobtitle} onChange={(e)=>{setJobtitle(e.target.value)}}  placeholder="Job title"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Education</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-graduation-cap "></i></div>
                        <input type="text" className="form-control" value={education} onChange={(e)=>{setEducation(e.target.value)}}  placeholder="Education"></input>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-auto"> 
                    {
                        userRole == "LEAD" ? <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("newVacancy")}}>Add</button>: <p></p>
                    }
                    
                    <button className="btn btn-sm btn-primary" onClick={vacancySearch}>Search</button>
                </div>
            </div> 
        </>
    );
}

export default VacancySearch;