import React, { useState } from 'react';
import { useLocation, useNavigate  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function CandidateFollowup() {
    const navigate = useNavigate ();
    const loc = useLocation();
    const [followupdate,setFollowupdate] = useState('');
    const [note,setNote] = useState('');
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const saveRecord = event =>{
        var data = {
            "canid": loc.state.candidateid,
            "followupdate" : followupdate,
            "note": note,
        };
        try{
            console.log(JSON.stringify(data));
            api.post("/candidates/followup",data,{headers:head});
            event.currentTarget.disabled = true;
            event.preventDefault();
        }catch(e){
          
        }
    }
    return (
        <>
           <div className="row mt-2">
                <h5>New Followup Details</h5>
                <div className="row p-2">
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Date</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-bars "></i></div>
                            <input type="date" value={followupdate} onChange={(e)=>setFollowupdate(e.target.value)} className="form-control"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Notes</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-edit"></i></div>
                            <input type="text" size="40" value={note} onChange={(e)=>setNote(e.target.value)} className="form-control" placeholder="Notes"></input>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-auto"> 
                        <button className="btn btn-primary" onClick={saveRecord}>Save</button>
                    </div>
                    <div className="col-auto"> 
                        <button className="btn btn-primary" onClick={()=>{navigate(-1)}}>Cancel</button>
                    </div>
                </div>
            </div> 
        </>
    );
}

export default CandidateFollowup;