import React, { useState,useEffect } from 'react';
import { useNavigate,createSearchParams  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function CandidateSearch(props) {
    const navigate = useNavigate ();
    const [name,setName] = useState('');
    const [mobile,setMobile] = useState('');
    const [education,setEducation]= useState('');
    const [jobfunction,setJobfunction] = useState('');
    const [area,setArea] = useState('');
    const [district,setDistrict] = useState('');
    const [potentialcan,setPotentialcan] = useState('');
    const [prefLocation,setPrefLocation]=useState('');
    const [experience,setExperience] = useState('');
    const [state,setState]=useState('');
    const [tags,setTags]=useState([]);

    const token = sessionStorage.getItem('portalhr.token');
    const crole = sessionStorage.getItem('portalhr.role');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const gotoSearch=(e)=>{
        navigate({pathname :"search",
                  search: createSearchParams({name:name,mobile:mobile,education:education,jobfunction:jobfunction,area:area,district:district,potentialcan:potentialcan,state:state,prefLocation:prefLocation,experience:experience}).toString()
                })
        e.preventDefault();
    }

    const gotoNewCandiate=(e)=>{
        navigate("newCandidate",{state:{mode:"NEW"}});
        e.preventDefault();
    }

    const clearForm = (e)=>{
        setArea("");
        setDistrict("");
        setEducation("");
        setMobile("");
        setName("");
        setPotentialcan("");
        setJobfunction("");
        setPrefLocation("");
        setExperience("");
        setState("");
        e.preventDefault();
    }

    const listCandidates = (e,t) =>{
        const tagname = t.tag;
        navigate({pathname :"tagentity_search_candidates",
                  search: createSearchParams({tag:tagname}).toString()
                })
        e.preventDefault();
    }

    useEffect(()=>{
        const search = {entitytype:"candidates"};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/tags/cagetory",search,{headers:head});
                setTags(dt.data);
            }catch(e){
            }
        }
        
        fetchData();
       
        
    },[]);

    return (
        <>
           <div className='row'>
                <div className='col-8'>
                    <h5>Candidate Search</h5>
                    <div className="row mt-2">
                            <div className="col-4">
                                <label className="visually-hidden" >Name</label>
                                <div className="input-group">
                                    <div className="input-group-text"><i className="fa fa-user"></i></div>
                                    <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)} placeholder="Username"></input>
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="visually-hidden" >Phone</label>
                                <div className="input-group">
                                    <div className="input-group-text"><i className="fa fa-phone"></i></div>
                                    <input type="text" className="form-control" value={mobile} onChange={(e)=>setMobile(e.target.value)} placeholder="Mobile phoneno"></input>
                                </div>
                            </div>
                            <div className="col-4">
                                <label className="visually-hidden" >Degree</label>
                                <div className="input-group">
                                    <div className="input-group-text"><i className="fa fa-graduation-cap "></i></div>
                                    <input type="text" className="form-control" value={education} onChange={(e)=>setEducation(e.target.value)} placeholder="Education"></input>
                                </div>
                            </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <label className="visually-hidden" >State</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-map"></i></div>
                                <select className='form-select ' value={state} onChange={(e)=>setState(e.target.value)}>
                                    < option value=""></option>
                                    < option value="Andhra Pradesh">Andhra Pradesh</option>
                                    < option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    < option value="Assam">Assam</option>
                                    < option value="Bihar">Bihar</option>
                                    < option value="Chhattisgarh">Chhattisgarh</option>
                                    < option value="Goa">Goa</option>
                                    < option value="Gujarat">Gujarat</option>
                                    < option value="Haryana">Haryana</option>
                                    < option value="Himachal Pradesh">Himachal Pradesh</option>
                                    < option value="Jharkhand">Jharkhand</option>
                                    < option value="Karnataka">Karnataka</option>
                                    < option value="Kerala">Kerala</option>
                                    < option value="Madhya Pradesh">Madhya Pradesh</option>
                                    < option value="Maharashtra">Maharashtra</option>
                                    < option value="Manipur">Manipur</option>
                                    < option value="Meghalaya">Meghalaya</option>
                                    < option value="Mizoram">Mizoram</option>
                                    < option value="Nagaland">Nagaland</option>
                                    < option value="Odisha">Odisha</option>
                                    < option value="Punjab">Punjab</option>
                                    < option value="Rajasthan">Rajasthan</option>
                                    < option value="Sikkim">Sikkim</option>
                                    < option value="Tamil Nadu">Tamil Nadu</option>
                                    < option value="Telangana">Telangana</option>
                                    < option value="Tripura">Tripura</option>
                                    < option value="Uttar Pradesh">Uttar Pradesh</option>
                                    < option value="Uttarakhand">Uttarakhand</option>
                                    < option value="West Bengal">West Bengal</option>
                                </select>  
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="visually-hidden" >Area</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-street-view"></i></div>
                                <input type="text" className="form-control" value={area} onChange={(e)=>setArea(e.target.value)} placeholder="Area"></input>
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="visually-hidden" >District</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-map-marker"></i></div>
                                <input type="text" className="form-control" value={district} onChange={(e)=>setDistrict(e.target.value)} placeholder="District"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <label className="visually-hidden" >Total Experience</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-server"></i></div>
                                <input type="text" value={experience} onChange={(e)=>setExperience(e.target.value)} className="form-control" placeholder="Total Experience"></input>
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="visually-hidden" >JOBFUNCTION</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-cogs"></i></div>
                                <input type="text" className="form-control" value={jobfunction} onChange={(e)=>setJobfunction(e.target.value)} placeholder="Job function"></input>
                            </div>
                        </div>
                        <div className="col-4">
                            <label className="visually-hidden" >Potentialcan</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-puzzle-piece"></i></div>
                                <input type="text" className="form-control" value={potentialcan} onChange={(e)=>setPotentialcan(e.target.value)} placeholder="Potential Employeer"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-4">
                            <label className="visually-hidden" >Pref Location</label>
                            <div className="input-group">
                                <div className="input-group-text"><i className="fa fa-street-view"></i></div>
                                <input type="text" value={prefLocation} onChange={(e)=>setPrefLocation(e.target.value)} className="form-control" placeholder="Preferred Location"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-auto"> 
                            <button className="btn btn-sm btn-primary" onClick={gotoNewCandiate}>New</button>
                        </div>
                        <div className="col-auto"> 
                            <button className="btn btn-sm btn-primary" onClick={gotoSearch}>Search</button>
                        </div>
                        <div className="col-auto"> 
                            <button className="btn btn-sm btn-primary" onClick={clearForm}>Clear</button>
                        </div>
                    </div> 
                </div>
                {
                    crole=='FRONTDESK_COMMENTTHIS' &&
                    <div className='col-4'>
                    <h5>Cloud Search</h5>
                    <div className='d-flex flex-wrap'>
                        {
                        tags.map((t,i)=>(
                            <button key={i} type="button" className="m-2 btn btn-sm btn-primary position-relative" onClick={(e)=>listCandidates(e,t)}>
                            {t.tag}
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                            {t.strength}
                            </span>
                            </button>
                        ))
                        }
                    </div>
                    </div>
                }
           </div>
        </>
    );
}

export default CandidateSearch;