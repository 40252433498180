import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function VacancyAssignment(props) {
    const navigate = useNavigate ();
    const loc = useLocation();
    const [users,setUsers] = useState([]);
    const [assigned,setAssigned]=useState(0);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/users/frontdeskusers";
                var dt = await api.get(url,{headers:head});
                setUsers(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    const assignJob= (e)=>{
        e.preventDefault();
        var data={"id":loc.state.record.id,"assignedTo": assigned};
        api.post("/jobs/assignJob",data,{headers:head});
        e.currentTarget.disabled = true;
    }

    return (
        <>
            <h5>VacancyAssignment</h5>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Job Title</th>
                        <th>Education</th>
                        <th>Experience</th>
                        <th>No of Posts</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{loc.state.record.name}</td>
                        <td>{loc.state.record.jobtitle}</td>
                        <td>{loc.state.record.education}</td>
                        <td>{loc.state.record.experience}</td>
                        <td>{loc.state.record.noofjobs}</td>
                    </tr>
                </tbody>
            </table>
            <div class="col-3">
                <label  className="fw-bold">Employee</label>
                <select className="form-select" value={assigned} onChange={(e)=>{setAssigned(e.target.value)}}>
                    <option selected key={-1}>Choose...</option>
                    {
                        users.map((user,i)=>(
                            <option value={user.id} key={i}>{user.name}</option>
                        ))
                    }
                </select>
            </div>
            <div class="mt-2">
                <button className="btn btn-sm btn-primary me-2" onClick={assignJob}>Assign</button>
                <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate(-1)}}>Close</button>
            </div>
        </>
    );
}

export default VacancyAssignment;