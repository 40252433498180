import React, { useEffect, useState,useContext } from 'react';
import api from '../../api/Axiosapi';

var initData={followups:15,interviews:13,reminders:14};

function Frontdesk_main_summary()
{
    const [data,setData] = useState(initData);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("frontdesk/summary",{headers:head});
                setData(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
    <div className="container-fluid pt-4 px-4">
        <div className="row g-4">
            <div className="col-sm-4 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                    <i className="fa fa-chart-line fa-3x text-primary"></i>
                    <div className="ms-3">
                        <p className="mb-2">Followups</p>
                        <h6 className="mb-0">{data.followups}</h6>
                    </div>
                </div>
            </div>
            <div className="col-sm-4 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                    <i className="fa fa-chart-bar fa-3x text-primary"></i>
                    <div className="ms-3">
                        <p className="mb-2">Interviews</p>
                        <h6 className="mb-0">{data.interviews}</h6>
                    </div>
                </div>
            </div>
            <div className="col-sm-4 col-xl-3">
                <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                    <i className="fa fa-chart-area fa-3x text-primary"></i>
                    <div className="ms-3">
                        <p className="mb-2">Reminders</p>
                        <h6 className="mb-0">{data.reminders}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}
    

export default Frontdesk_main_summary;