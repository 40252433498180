import React from 'react';
import { Outlet } from 'react-router-dom';
import Manager_sidebar from './Manager_sidebar';
import './app.css';
import Application_navbar from './Application_navbar';

function Managerhome() {
    return (
        <>
            <Manager_sidebar />
            <div className="content">
                <Application_navbar />
                <Outlet />
            </div>
        </>
    )
}

export default Managerhome;