import React , { useEffect, useState }from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Reminder(props) {
    const navigate = useNavigate ();
    const [invdate,setInvdate] = useState('');
    const [notes,setNotes] = useState('');
    const loc = useLocation();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const save = (e)=>{
        e.preventDefault();
        e.currentTarget.disabled = true;
        var data = {"canid":loc.state.canid,"followupdate":invdate,"note":notes};
        
        try{
            api.post("/candidates/followup",data,{headers:head});
        }catch (e) {
           
        }
        
    }

    return (
        <>
            <div className="row mt-2">
                <h5>Followup Details canid-{loc.state.canid}</h5>
                <div className="row p-2">
                    <div className="col-auto">
                        <label className="visually-hidden" for="autoSizingInputGroup">Date</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-bars "></i></div>
                            <input type="date" value={invdate} onChange={(e)=>{setInvdate(e.target.value)}} className="form-control"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label className="visually-hidden" for="autoSizingInputGroup">Notes</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-edit"></i></div>
                            <input type="text" size="40" value={setNotes} onChange={(e)=>{setNotes(e.target.value)}} className="form-control" placeholder="Notes"></input>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={save}>Save</button>
                    </div>
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={(e)=>{navigate(-1)}}>Close</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reminder;