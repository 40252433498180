import React,{useState,useEffect} from 'react';
import api from '../api/Axiosapi';

var initData={regCandidates:15,selCandidates:13,vacancies:14,assigned:0,processing:0};

function Manager_main_summary(props) {
    const [data,setData] = useState(initData);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("manager/summary",{headers:head});
                console.log(dt.data);
                setData(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
          <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
                <div className="col-sm-4 col-xl-3">
                    <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                        <i className="fa fa-chart-line fa-3x text-primary"></i>
                        <div className="ms-3">
                            <p className="mb-2">Registered Candidates</p>
                            <h6 className="mb-0">{data.regCandidates}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 col-xl-3">
                    <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                        <i className="fa fa-chart-bar fa-3x text-primary"></i>
                        <div className="ms-3">
                            <p className="mb-2">Selected Candidates</p>
                            <h6 className="mb-0">{data.selCandidates}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 col-xl-3">
                    <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                        <i className="fa fa-chart-area fa-3x text-primary"></i>
                        <div className="ms-3">
                            <p className="mb-2">New Vacancies</p>
                            <h6 className="mb-0">{data.vacancies}</h6>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4 col-xl-3">
                    <div className="bg-light rounded d-flex align-items-center justify-content-between p-4">
                        <i className="fa fa-chart-area fa-3x text-primary"></i>
                        <div className="ms-3">
                            <p className="mb-2">Processing Vacancies</p>
                            <h6 className="mb-0">{parseInt(data.assigned) + parseInt(data.processing)}</h6>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default Manager_main_summary;