import React from 'react';
import { Outlet } from 'react-router-dom';
import Frontdesk_sidebar from './Frontdesk_sidebar';
import './app.css';
import Application_navbar from './Application_navbar';

function Frontdeskhome() {
    return (
        <>
            <Frontdesk_sidebar />
            <div className="content">
                <Application_navbar />
                <Outlet />
            </div>
        </>
    )
}

export default Frontdeskhome;