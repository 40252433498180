import React from 'react';
import { Outlet } from 'react-router-dom';
import Lead_sidebar from './Lead_sidebar';
import './app.css';
import Application_navbar from './Application_navbar';

function Leadhome() {
    return (
        <>
            <Lead_sidebar />
            <div className="content">
                <Application_navbar />
                <Outlet />
            </div>
        </>
    )
}

export default Leadhome;