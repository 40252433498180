import React, { useState } from 'react';
import { useNavigate,createSearchParams } from 'react-router-dom';

function ReminderSearch() {
    const navigate = useNavigate ();
    const [phone,setPhone] = useState('');
    const [fromDate,setFromdate]= useState('');
    const [toDate,setTodate] = useState('');

    const searchReminders=(e)=>{
        e.preventDefault();
        var params={phone:phone,fromdate:fromDate,todate:toDate};
        navigate({pathname :"searchReminders",
                  search: createSearchParams(params).toString()
                })
    }

    const clear = (e)=>{
        e.preventDefault();
        setPhone('');
        setFromdate('');
        setTodate('');
    }
    return (
        <>
            <div className="row">
                <div className="col-auto">
                    <label  for="autoSizingInputGroup">Phone</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="Candidates Mobile phoneno"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label  for="autoSizingInputGroup">From Date</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="date" className="form-control" value={fromDate} onChange={(e)=>{setFromdate(e.target.value)}} ></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label  for="autoSizingInputGroup">To Date</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="date" className="form-control" value={toDate} onChange={(e)=>{setTodate(e.target.value)}}  ></input>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-auto"> 
                    <button className="btn btn-sm btn-primary me-2" onClick={searchReminders}>Search</button>
                    <button className="btn btn-sm btn-primary" onClick={clear}>clear</button>
                </div>
            </div>
        </>
    );
}

export default ReminderSearch;