
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initReminders=[
    {followupdate:"2022-10-02 09:00:00","note":"Call back for resume"},
    {followupdate:"2022-10-02 18:30:00","note":"Call back for resume"},
]

function ReminderListByCandidate(props) {
    const navigate = useNavigate ();
    const [reminders,setReminders] = useState(initReminders)
    const loc = useLocation();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/followups/" + loc.state.canid;
                var dt = await api.get(url,{headers:head});
                setReminders(dt.data);
            }catch(e){
            }
        }
        fetchData();
        
    },[])

    return (
    <>
      
      <div className="col-sm-12 col-md-6 col-xl-6">
        <table className='table' >
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Note</th>
                </tr>
            </thead>
            <tbody>
                {
                    reminders.map((reminder,i)=>(
                        <tr key={i}>
                            <td> {reminder.followupdate} </td>
                            <td> {reminder.note} </td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("followup",{state:{candidateid:loc.state.canid}})}}>New Followup</button>
      </div>
      <div className="col-sm-12 col-md-6 col-xl-6">
      <Outlet/>
      </div>
      
    </>
    );
}

export default ReminderListByCandidate;