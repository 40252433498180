import React ,{useState,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../../api/Axiosapi';

function CanSearchCriteria(props) {
    const [mobile,setMobile] = useState('');
    const [canid,setCanid] = useState(-1);
    const [intdate,setIntdate] = useState('');
    const [note,setNote]=useState('');
    
    const navigate = useNavigate();
    const [candidates,setCandidates]=useState([]);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    function performSearch(){
        const search = {mobile:mobile};
        
        const fetchData = async()=>{
            try{
                var dt = await api.post("/candidates/search",search,{headers:head});
                setCandidates(dt.data);
                setCanid(dt.data[0].id);
            }catch(e){
            }
        }
        fetchData();
    }

    const scheduleInterview =(event)=>{
       
        const data = {jobid:props.jobid,candidateid:canid,intvdate:intdate.replace("T"," "),managerid:props.mgrid,note:note};
        
        const fetchData = async()=>{
            try{
                var dt = await api.post("/interviews",data,{headers:head});
            }catch(e){
            }
        }
        fetchData();
        event.currentTarget.disabled = true;
        event.preventDefault();
    }

    return (
        <>
        <div className='w-75 me-2'>
            <div className="row w-75">
                <div className="col-auto">
                    <label className="visually-hidden" >Phone</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" className="form-control" value={mobile} onChange={(e)=>setMobile(e.target.value)} placeholder="Mobile phoneno"></input>
                        <button className="btn btn-sm btn-primary" onClick={()=>{performSearch()}}>Search</button>
                    </div>
                </div>
            </div>
            <div className="row mt-2 me-2">
                <div className='col'>
                    <select className="form-select" value={canid} onClick={(e)=>{setCanid(e.target.value);}} >
                    {
                        candidates.map((can,i)=>(
                            <option value={can.id}>{can.firstname} {can.lastname}</option>
                        ))
                    }
                    </select>
                </div>
                <div className='col'>
                    <label className="visually-hidden" >Date</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="datetime-local" className="form-control" value={intdate} onChange={(e)=>setIntdate(e.target.value)}></input>
                    </div>
                </div>
                <div className='col'>
                    <label className="visually-hidden" >note</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-edit"></i></div>
                        <input type="text" className="form-control" size={100}   value={note} onChange={(e)=>setNote(e.target.value)}></input>
                    </div>
                </div>
                
                
                <div className='col-1'>
                <label className="visually-hidden" >note</label>
                </div>
            </div>
            
            <button className='btn btn-sm btn-primary m-2' onClick={scheduleInterview}>Save</button>
            <button className='btn btn-sm btn-primary m-2' onClick={()=>{navigate(-1)}}>Close</button>
        </div>
        </>
    );
}

export default CanSearchCriteria;