import React,{ useState,useEffect } from 'react';
import { Link, Outlet, useNavigate,useSearchParams  } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initVacancies = [
    {name:"Molex India",jobtitle:" Mechanical Design Engineer",education:"B.E",experience:"5 + Years",novacancies:"1",id:"1"}
]

function VacanciesTabular(props) {
    const navigate = useNavigate ();
    const [vacancies,setVacancies] = useState(initVacancies);
    const [searchParams] = useSearchParams();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    const userRole = sessionStorage.getItem('portalhr.role');

    useEffect(()=>{
        var search = { "name":searchParams.get("name"),"jobtitle": searchParams.get("jobtitle"),"education": searchParams.get("education") }
        const fetchData = async()=>{
            try{
                var url = "/jobs/search";
                var dt = await api.post(url,search,{headers:head});
                console.log(dt.data);
                setVacancies(dt.data);
            }catch(e){
            }
        }
        if(!(searchParams.get("name")==null && searchParams.get("jobtitle")==null && searchParams.get("education")==null )){
            fetchData();
        }
        
    },[searchParams])

    return (
        <>
            
            <table className='table'>
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Job Title</th>
                        <th>Education</th>
                        <th>Experience</th>
                        <th>No of Posts</th>
                        <th>Status</th>
                        <th>Assigned to</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        vacancies.map((vac,i)=>(
                            <tr key={i}>
                                <td>{vac.name}</td>
                                <td>{vac.jobtitle}</td>
                                <td>{vac.education}</td>
                                <td>{vac.experience}</td>
                                <td>{vac.noofjobs}</td>
                                <td>{vac.status}</td>
                                <td>{vac.employee}</td>
                                <td>
                                    {
                                        userRole=="LEAD" ?
                                        <button className="btn btn-sm btn-primary me-2" onClick={(e)=>{navigate("vacancyAssignment",{state:{record:vac}})}}>Assign</button>
                                        : <p></p>

                                    }
                                    
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table> 
            <div>
            <Outlet />
            </div>
            
        </>
    );
}

export default VacanciesTabular;