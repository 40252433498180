import React, { useEffect,useState,useContext } from 'react';
import { useNavigate ,createSearchParams } from 'react-router-dom';
import api from '../../api/Axiosapi';

function InterviewSearch(props) {
    const navigate = useNavigate ();
    const [emplopyeers,setEmployeers] = useState([]);
    const [empid,setEmpid]=useState('');
    const [intDate,setintDate] = useState('');
    const [phone,setPhone] = useState('');
    const [stat,setStatus] = useState('');

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/institutions";
                var dt = await api.get(url,{headers:head});
                console.log(dt.data);
                setEmployeers(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    const searchInterviews =(e)=>{
        e.preventDefault();
        var params = {intDate:intDate,phone:phone};
        if(empid!=-1){
            params["empid"]=empid;
        }
        if(stat!=-1){
            params["status"]=stat;
        }
        
        navigate({pathname :"searchInterviews",
                  search: createSearchParams(params).toString()
                })
    }

    const clearform = (e) =>{
        e.preventDefault();
        setEmpid(-1);
        setintDate('');
        setPhone('');
    }
    return (
        <>
            <form className="gy-2 gx-3 align-items-center">
                <div className="row">
                    <div className="col-auto">
                        <label className="visually-hidden">Name</label>
                        <div className="input-group">
                            <div className="input-group-text">Employer</div>
                            <select value={empid} onChange={(e)=>{setEmpid(e.target.value)}}>
                                <option key={-1} value={-1}> </option>
                                {
                                    emplopyeers.map((emp,i)=>(
                                        <option key={i} value={emp.id}>{emp.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>

                    <div className="col-auto">
                        <label className="visually-hidden" >Date</label>
                        <div className="input-group">
                            <div className="input-group-text">Date</div>
                            <input type="date" className="form-control" value={intDate} onChange={(e)=>{setintDate(e.target.value)}}></input>
                        </div>
                    </div>
                    
                    <div className="col-auto">
                        <label className="visually-hidden" >Candidate</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-phone"></i></div>
                            <input type="Number" size={10} className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="Candidates phone no"></input>
                        </div>
                    </div>
                    <div className="col-auto mt-2">
                        <label className="visually-hidden" >Status</label>
                        <div className="input-group">
                            <div className="input-group-text">Status</div>
                            <select className='form-select ' value={stat} onChange={(e)=>setStatus(e.target.value)}>
                                <option value="-1"> </option>
                                <option value="SCHEDULED">SCHEDULED</option>
                                <option value="RESCHEDULED">RESCHEDULED</option>
                                <option value="REJECTED">REJECTED</option>
                                <option value="SELECTED">SELECTED</option>
                                <option value="ONHOLD">ONHOLD</option>
                                <option value="OFFERED">OFFERED</option>
                                <option value="JOINED">JOINED</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={searchInterviews}>Search</button>
                    </div>
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={clearform}>Clear</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default InterviewSearch;