import React, { useEffect,useState } from 'react';
import reminderAvatar from './reminder.png';
import api from '../../api/Axiosapi';


var initReminderlist = [
    {name:"RAMESH R",time:"13-8-2022 @ 11.30 AM",post:"Production Engineer",employer:"Messo Infra"},
    {name:"Vijay Shankar",time:"14-8-2022 @ 1.30 AM",post:"Engineer",employer:"Innovations"},
    {name:"Dev Raj",time:"15-8-2022 @ 2.30 AM",post:"Consultant",employer:"Drishti Solutions"},
    {name:"Viraprajwal",time:"14-8-2022 @ 11.30 AM",post:"Supervisor",employer:"Messy Infrastructure"}
]

function Frontdesk_main_widget_Reminders() {
    const [reminders,setReminders] = useState(initReminderlist)
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("interviews/todaysreminder/5",{headers:head});
                setReminders(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="h-100 bg-light rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="mb-0">Reminders</h6>
                    
                </div>
                {
                    reminders.map((reminder,i)=>(
                    <div key={i} className="d-flex align-items-center border-bottom py-3">
                        <img className="rounded-circle flex-shrink-0 femaleAvatar" src={reminderAvatar} />
                        <div className="w-100 ms-3">
                            <div className="d-flex w-100 justify-content-between">
                                <h6 className="mb-0">{reminder.name}</h6>
                                <small>{reminder.time}</small>
                            </div>
                            <span><small>{reminder.post} - {reminder.employer}</small></span>
                        </div>
                    </div>
                    ))
                    
                }
            </div>
        </div>
    );
}

export default Frontdesk_main_widget_Reminders;