import React, { useEffect, useState } from 'react';
import { useLocation, Outlet} from 'react-router-dom';
import CanSearchCriteria from './CanSearchCriteria';
import api from '../../api/Axiosapi';

function InterviewCanAssign() {
    const loc = useLocation();
    const [contacts,setContacts] = useState([]);
    const [mgrId,setmgrID] = useState(0);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const url = "/institutions/" + loc.state.client.id + "/contacts"
        const fetchData = async()=>{
            try{
                var dt = await api.get(url,{headers:head});
                setContacts(dt.data);
                setmgrID(dt.data[0].id);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <>
        <h5>Interview Schedule for {loc.state.record.jobtitle} @ {loc.state.client.name}</h5>
        
        <h5 className="mt-2">Select Contact</h5>
        <table className='table m-2'>
            <thead>
                <tr>
                    <th>select</th>
                    <th>Name</th>
                    <th>Designation</th>
                    <th>Division</th>
                    <th>phone</th>
                </tr>
            </thead>
            <tbody>
                {
                    contacts.map((contact,i)=>(
                        <tr key={i}>
                            <td>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name='mgrid' value={contact.id} onClick={(e)=>{setmgrID(e.target.value)}}></input>
                                </div>
                            </td>
                            <td>{contact.name}</td>
                            <td>{contact.designation}</td>
                            <td>{contact.devision}</td>
                            <td>{contact.phone}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
        <h5 className="m-2">Seach Candidate : </h5>
        <CanSearchCriteria jobid={loc.state.record.id} mgrid={mgrId} />
        <Outlet />
        </>
    );
}

export default InterviewCanAssign;