import React ,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import femaleAvatar from './female-avatar.png';


function Application_navbar() {
    const [user,setUser] = useState(sessionStorage.getItem('portalhr.user'));
    const navigate = useNavigate ();
    const logout=(e)=>{
        sessionStorage.setItem("portalhr.user","");
        sessionStorage.setItem("portalhr.token","");
        sessionStorage.setItem("portalhr.role","");
        navigate("/login");
    }
    return (
        <nav className="navbar navbar-expand bg-light navbar-light sticky-top px-4 py-0">
            <div className="mx-auto">
                <h3>Application Tracking System</h3>
            </div>
            <div className="navbar-nav align-items-center ms-auto">
                
                <div className="nav-item dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
                        <img className="rounded-circle me-lg-2 femaleAvatar" src={femaleAvatar} alt="" />
                        <span className="d-none d-lg-inline-flex">{user}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-end bg-light border-0 rounded-0 rounded-bottom m-0">
                        <a href="#" onClick={logout} className="dropdown-item">Log Out</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Application_navbar;
