import React from 'react';
import './Frontdesk_main.css';
import Frontdesk_main_summary from '../components/Summary/Frontdesk_main_summary';
import Frontdesk_main_TodaysFollowups from '../components/Candidate/Frontdesk_main_TodaysFollowups';
import Frontdesk_main_widget_Interviews from '../components/Interview/Frontdesk_main_widget_Interviews';
import Frontdesk_main_widget_Reminders from '../components/Reminder/Frontdesk_main_widget_Reminders';

function Frontdesk_main() {
    return (
        <>
            <Frontdesk_main_summary />
            <Frontdesk_main_TodaysFollowups />
            <div className="container-fluid pt-4 px-4">
                <div className="row g-4">
                    <Frontdesk_main_widget_Interviews />
                    <Frontdesk_main_widget_Reminders />
                </div>
            </div>
        </>
    )
}

export default Frontdesk_main;