import React from 'react';
import CandidateSearch from '../components/Candidate/CandidateSearch';
import { Outlet } from 'react-router-dom';

function Frontdesk_candidates() {
    return (
        <>
            <div className='m-2'>
            <CandidateSearch />
            <br></br>
            <Outlet />
            </div>
        </>
    );
}

export default Frontdesk_candidates;