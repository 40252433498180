import React,{useState} from 'react';
import femaleAvatar from './female-avatar.png';
import { NavLink } from 'react-router-dom';

function Supervisor_sidebar(){
    const [user,setUser] = useState(sessionStorage.getItem('portalhr.user'));
    return(
    <div className="sidebar pe-4 pb-3">
        <nav className="navbar bg-light navbar-light">
            <a href="index.html" className="navbar-brand mx-4 mb-3">
                <h3 className="text-primary">ATS</h3>
            </a>
            <div className="d-flex align-items-center ms-4 mb-4">
                <div className="position-relative">
                    <img className="rounded-circle femaleAvatar" src={femaleAvatar} alt=""  />
                    <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1">   
                    </div>
                </div>
                <div className="ms-3">
                    <h6 className="mb-0">{user}</h6>
                    <span>Supervisor</span>
                </div>
            </div>
            <div className="navbar-nav w-100">
                <NavLink className="nav-item nav-link" activeClassName="active" to="/supervisor">
                    <i className="fa fa-home me-2"></i>Home
                </NavLink>
            </div>
        </nav>
    </div>
    );
}

export default Supervisor_sidebar;