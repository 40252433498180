import React,{ useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';

import api from '../../api/Axiosapi';


const initData =[{candidate:"candidate",intvdate:"intvdate",jobstatus:"jobstatus"}];


function CandidatesForVacancy(props) {
    const loc = useLocation();
    const navigate = useNavigate();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    const [candidates,setCandidates] = useState([initData]);
    useEffect(()=>{
        const fetchData = async()=>{
            var data = {
                "jobid": loc.state.job.id
            };
            try{
                var url = "/interviews/listCandidatesForJob";
                var dt = await api.post(url,data,{headers:head});
                setCandidates(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[loc.state])

    return (
        <div className='mt-2'>
            <h5>Candidates for {loc.state.job.jobtitle} @ {loc.state.client}</h5>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Candidate</th>
                        <th>Interview</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        candidates.map((candidate,i)=>(
                         <tr key={i}>
                            <td>{candidate.candidate}</td>
                            <td>{candidate.intvdate}</td>
                            <td>{candidate.jobstatus}</td>
                         </tr>
                        ))
                    }
                </tbody>
            </table>
            <button className='btn btn-sm btn-primary m-2' onClick={()=>{navigate(-1)}}>Close</button>
        </div>
    );
}

export default CandidatesForVacancy;