import React from 'react';
import { Outlet } from 'react-router-dom';
import SelectedCandidatesSearch from '../components/Candidate/SelectedCandidatesSearch';

function Manager_selectedcandidates() {
    return (
        <>
            <SelectedCandidatesSearch />
            <br></br>
            <Outlet />
        </>
    );
}

export default Manager_selectedcandidates;