import React from 'react';
import UsersList from '../components/User/UsersList';
import { Outlet } from 'react-router-dom';
import { useNavigate  } from 'react-router-dom';

function Manager_user(props) {
    const navigate = useNavigate ();
    return (
        <>
            <button className='btn btn-sm btn-primary mb-2' onClick={()=>{navigate('newUser',{state:{mode:"NEW"}})}}>Create User</button>
            <UsersList />
            <br></br>
            <Outlet />
        </>
    );
}

export default Manager_user;