import React from 'react';
import ReminderSearch from '../components/Reminder/ReminderSearch';
import { Outlet } from 'react-router-dom';
function FrontdeskFollowups() {
    
    return (
        <>
            <ReminderSearch />
            <br></br>
            <Outlet />
        </>
        
    )
}

export default FrontdeskFollowups;