import axios from "axios";
//const BASE_URL = 'http://localhost:8080';
const BASE_URL = 'https://portalhr.dhanyavad.co.in/public';

/*
function create(){
    const user = useContext(AuthContext);

    const headers = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization':user[0].token};
    const config = {
        baseURL: BASE_URL,
        headers: headers,
        mode: 'no-cors',
        withCredentials: true,
        credentials: 'same-origin',
    }
    return axios.create(config);
}
export default create();
*/

export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json' },
    mode: 'no-cors',
    withCredentials: true,
    credentials: 'same-origin',
});
