import React from 'react';
import { Outlet } from 'react-router-dom';
import VacancySearch from '../components/Client/VacancySearch';
function Manager_vacancies(props) {
    return (
        <>
            <VacancySearch />
            <br></br>
            <Outlet />
            
        </>
    );
}

export default Manager_vacancies;