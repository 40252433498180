import React, { useState } from 'react';
import { useNavigate,createSearchParams  } from 'react-router-dom';


function SelectedCandidatesSearch(props) {
    const navigate = useNavigate ();
    const [fromDate,setFromdate] = useState('');
    const [toDate,setTodate] = useState('');

    const gotoSearch=(e)=>{
        navigate({pathname :"search",
                  search: createSearchParams({fromDate:fromDate,toDate:toDate}).toString()
                })
        e.preventDefault();
    }

    return (
        <>
           <div className="row">
                <h5>Search For Selected Candidates</h5>
                <div className="col-auto">
                    <label className="visually-hidden" >From Date</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="date" className="form-control" value={fromDate} onChange={(e)=>setFromdate(e.target.value)} placeholder="From Date"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >To Date</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="date" className="form-control" value={toDate} onChange={(e)=>setTodate(e.target.value)} placeholder="To Date"></input>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-auto"> 
                    <button className="btn btn-sm btn-primary" onClick={gotoSearch}>Search</button>
                </div>
            </div> 
        </>
    );
}

export default SelectedCandidatesSearch;