import React from 'react';
import Manager_main_summary from './Manager_main_summary';
import AgentScore from '../components/Summary/AgentScore';
//import VacancyDistribution from '../components/Summary/VacancyDistribution';
//<VacancyDistribution />
function Manager_main() {
    return (
        <>
            <Manager_main_summary />
            
            <AgentScore />
        </>
    );
}

export default Manager_main;