import React, { useEffect, useState } from 'react';
import api from '../../api/Axiosapi';


function AgentScore(props) {
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const [ascore,setAscore] = useState([]);

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("/user/performance",{headers:head});
                setAscore(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <>
        <div className="row pt-2">
            <h5>Agents Job Assignment</h5>
            <div className="row row-cols-1 row-cols-md-5 ">
                {
                    ascore.map((score,i)=>(
                        <div className="col m-2" key={i}>
                            <div className="card h-100">
                                <div className="card-header bg-primary text-white">
                                {score.name}
                                </div>
                                <div className="card-body">
                                    <small>
                                    <ul className="">
                                        <li>Jobs assigned  : {score.totaljobs}</li>
                                    </ul>
                                    </small>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
            
        </>
    );
}

export default AgentScore;