import React,{useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
import femaleAvatar from './female-avatar.png';


function Manager_sidebar(){
    const [user,setUser] = useState(sessionStorage.getItem('portalhr.user'));

    return(
    <div className="sidebar pe-4 pb-3">
        <nav className="navbar bg-light navbar-light">
            <a href="index.html" className="navbar-brand mx-4 mb-3">
                <h3 className="text-primary">ATS</h3>
            </a>
            <div className="d-flex align-items-center ms-4 mb-4">
                <div className="position-relative">
                    <img className="rounded-circle femaleAvatar" src={femaleAvatar} alt=""  />
                    <div className="bg-success rounded-circle border border-2 border-white position-absolute end-0 bottom-0 p-1">   
                    </div>
                </div>
                <div className="ms-3">
                    <h6 className="mb-0">{user}</h6>
                    <span>{user[0].role}</span>
                </div>
            </div>
            <div className="navbar-nav w-100">
                <NavLink className="nav-item nav-link" activeClassName="active" to="/frontdesk/">
                    <i className="fa fa-home me-2"></i>Home
                </NavLink>
                <NavLink className="nav-item nav-link" activeClassName="active" to="/frontdesk/candidates">
                    <i className="fa fa-user me-2"></i>Candidates
                </NavLink>
                <NavLink className="nav-item nav-link" activeClassName="active" to="/frontdesk/institutions">
                    <i className="fa fa-building me-2"></i>Institutions
                </NavLink>
                <NavLink className="nav-item nav-link" activeClassName="active"  to="/frontdesk/interviews">
                    <i className="fa fa-comments me-2"></i>Interviews
                </NavLink>
                <NavLink className="nav-item nav-link" activeClassName="active"  to="/frontdesk/followups">
                <i className="fa fa-check-square me-2"></i>Follow ups
                </NavLink>
            </div>
        </nav>
    </div>
    );
}

export default Manager_sidebar;