import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate ,useSearchParams } from 'react-router-dom';
import api from '../../api/Axiosapi';
import VisualCalendarDay from '../visual/VisualCalendarDay';

var initList =[
    {employername:"Molex India",candidate:"Mohan",post:"Mechanical Design Engineer",intvdate:"24-09-2022",id:"1",status:""}
]

function InterviewList() {
    const navigate = useNavigate ();
    const [interviews,setInterviews] = useState(initList);
    const [searchParams] = useSearchParams();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    const [rawdata,setRawdata] = useState(initList);

    //filters rawdata and assign filtered data in interviews
    function filterDataset(fieldname,fieldvalue){
        let newdata = rawdata.filter(rdata=>rdata[fieldname]==fieldvalue);
        setInterviews(newdata);
    }

    function resetDataset(){
        setInterviews(rawdata);
    }

    useEffect(()=>{
        const search = {empid:searchParams.get("empid"),intDate:searchParams.get("intDate"),phone: searchParams.get("phone"),status:searchParams.get("status")};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/interviews/search",search,{headers:head});
                setInterviews(dt.data);
                setRawdata(dt.data);
                console.log(dt.data);
            }catch(e){
            }
        }
        if(!(search.empid === null && search.intDate === null && search.phone === null)){
            fetchData();
        }
        
    },[searchParams])


    return (
        <>
            <div className="row">
                <div className="col-auto">
                    <h5>Interview List</h5>
                </div>
                <div className="col-auto">
                    <button className="btn btn-sm btn-primary" onClick={()=>{filterDataset("status","RESCHEDULE")}}>Filter</button>
                </div> 
            </div>
            
            <div className='d-flex d-flow'>
                {
                    interviews.map((interview,i)=>(
                        <div className='m-1' key={i}>
                            <VisualCalendarDay eventdate={interview.intvdate} />
                            <ul className="list-group list-group-flush">
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Employer : {interview.employername}</small>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Candidate : {interview.firstname} {interview.lastname}</small>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Job Title : {interview.jobtitle}</small>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Manager : {interview.manager} </small>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Status : {interview.status}</small>
                            </a>
                            <a href="#" className="list-group-item list-group-item-action">
                                <small>Note : {interview.note}</small>
                            </a>
                                
                            </ul>
                            <button className="btn btn-sm btn-primary mt-2" onClick={()=>{navigate('interviewDetails',{state:{record:interviews[i]}})}}>Details</button>
                        </div>
                    ))
                }
            </div>
            <Outlet />
        </>
    );
}

export default InterviewList;