import React from 'react';
import { Outlet } from 'react-router-dom';
import EmployeeSearch from '../components/Employee/EmployeeSearch';
function Supervisor_main(props) {
    return (
        <>
          <EmployeeSearch />
          <br></br>
          <Outlet />
        </>
    );
}

export default Supervisor_main;