import React, { useEffect, useState } from 'react';
import {Outlet, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/Axiosapi';


var initVacancies=[
    {
        id:1,
        jobtitle:"",
        instid:6,
        education:"",
        experience: 6,
        department:"",
        jobDate:"",
        noofjobs:1,
        status:"",
        assignedTo: 0,
        CTC:5.5,
        name:"",
        skill:""
    }
]

function Vacancies(props) {
    const navigate = useNavigate ();
    const [vacancies,setVacancies]=useState(initVacancies);
    const [clientname,setClientname]=useState('');
    const loc = useLocation();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        setClientname(loc.state.record.name);
        const fetchData = async()=>{
            try{
                var url = "/userinst/"+ loc.state.record.id+"/jobs";
                var dt = await api.get(url,{headers:head});
                setVacancies(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    const gotoAssign = (e,vacancy)=>{
        navigate("assigncaninterview",{state:{record:vacancy,client:{name:loc.state.record.name,id:loc.state.record.id}}});
        e.preventDefault();
    }

    const gotoCandidates = (e,vacancy)=>{
        navigate("listCandidatesForVacancy",{state:{client:clientname,job:vacancy}});
        e.preventDefault();
    }

    return (
        <>
        <div className="p-2">
            <div className="row">
                <div className="col"><h5>Vacancies List @ {clientname}</h5></div>
            </div>
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {
                    vacancies.map((vacancy,id)=>(
                        <div className="col" key={id}>
                            <div className="card h-100">
                                <div className="card-header bg-primary text-white">
                                    {vacancy.jobtitle}
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                    <div className='col'>
                                        <div className="row">
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Education</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.education}</span>
                                                </small>
                                            </div>
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Experience</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.experience}</span>
                                                </small>
                                            </div>
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Department</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.department}</span>
                                                </small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Job Date</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.jobDate}</span>
                                                </small>
                                            </div>
                                            <div className="col">
                                                <span className=" border-danger border-bottom "># Positions</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.noofjobs}</span>
                                                </small>
                                            </div>
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Status</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.status}</span>
                                                </small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">CTC</span><br></br>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.CTC}</span>
                                                </small>
                                            </div>
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Assigned</span>
                                                <small>
                                                    <span className="fw-bolder"> {vacancy.name}</span>
                                                </small>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <span className=" border-danger border-bottom ">Skill</span>
                                                <small>
                                                    <div className="text-wrap" >
                                                        {vacancy.skill}
                                                    </div>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="demo" className='col-3 collapse' >
                                        Analysis
                                    </div>
                                    </div>
                                    
                                </div>
                                <div className="card-footer">
                                    <button className='btn btn-primary btn-sm m-2' onClick={(e)=>gotoAssign(e,vacancy)}>Assign</button>
                                    <button className='btn btn-primary btn-sm m-2' onClick={(e)=>gotoCandidates(e,vacancy)}>Candidates</button>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="row">
                <Outlet />
            </div>
        </div>
        
        </>
    );
}

export default Vacancies;