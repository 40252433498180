import React, { useEffect, useState }  from 'react';
import { Outlet, useNavigate,useSearchParams } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initCanList = [{"employername":"","instid":"","manager":"","mgrphone":"","education":"","designation":"","experience":"","intvdate":"","status":"","intvid":"","canid":""}];

function SelectedCandidatesList(props) {
    const navigate = useNavigate ();
    const [searchParams] = useSearchParams();
    const [candidates,setCandidates]=useState(initCanList);
    const token = sessionStorage.getItem("portalhr.token");
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const search = {fromDate:searchParams.get("fromDate"),toDate:searchParams.get("toDate")};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/interviews/selectedcandidates",search,{headers:head});
                setCandidates(dt.data);
                
            }catch(e){
            }
        }
        
        if(!(search.fromDate === null && search.toDate === null)){
            fetchData();
        }
        
    },[searchParams])

    return (
        <>
            <div className="row mt-2">
                <table className="table" >
                    <thead>
                        <tr>
                            <th>Employer</th>
                            <th>Manager</th>
                            <th>M.Phone</th>
                            <th>C.Education</th>
                            <th>Designation</th>
                            <th>Experience</th>
                            <th>Interview Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            candidates.map((can,i)=>(
                                <tr key={i}>
                                    <td>{can.employername}</td>
                                    <td>{can.manager}</td>
                                    <td>{can.mgrphone}</td>
                                    <td>{can.education}</td>
                                    <td>{can.designation}</td>
                                    <td>{can.experience}</td>
                                    <td>{can.intvdate}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                    
                </table>
            </div>
            <div className="row mt-2">
                <Outlet />
            </div>
        </>
    );
}

export default SelectedCandidatesList;