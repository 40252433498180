import {React,useState,useEffect} from "react";
import { useNavigate  } from 'react-router-dom';
import api from '../../api/Axiosapi';

/*
https://www.youtube.com/watch?v=nI8PYZNFtac&list=RDCMUCY38RvRIxYODO4penyxUwTg&index=2
*/
/* Get data using axios 
    useEffect(()=>{
        let isMounted=true;
        const controller = new AbortController();
        const getUsers = async()=>{
            try{
                const response = axios.get('/users',{signal:controller.signal});
                console.log(response.data);
                setUsers(response.data);
            }catch(err){
                console.log(err);
            }
        }
        getUsers()
        return()=>{
            isMounted=false;
            controller.abort();
        }
    },[])
    */
var initUsers=[
    {name:"ONe",id:"1",role: "ROLE1"},
    {name:"Two",id:"2",role: "ROLE2"}
]

function UsersList() {

    const navigate = useNavigate ();
    const [users,setUsers] = useState(initUsers);

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/users/roles";
                var dt = await api.get(url,{headers:head});
                setUsers(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])
    
   
    return (
        <div className="row">
            <h5>Users</h5>
            <table className="ms-3">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user,id)=>(
                                <tr key={id}>
                                    <td>{user.name}</td>
                                    <td>{user.role}</td>
                                    <td><button className="btn btn-sm btn-primary" onClick={()=>{navigate('newUser',{state:{mode:"EDIT",record:user}})}}>Details</button></td>
                                </tr>
                            )
                        )
                    } 
                </tbody>
                
            </table>
        </div>
    );
}
export default UsersList;