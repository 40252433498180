import React, { useEffect, useState } from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Candidate() {
    const loc = useLocation();
    const navigate = useNavigate ();
    const [firstname,setFirstname] = useState('');
    const [lastname,setLastName] = useState('');
    const [mobile,setMobile] = useState('');
    const [email,setEmail] = useState('');
    const [dob,setDob] = useState('');
    const [gender,setGender] = useState('MALE');
    const [education,setEducation] = useState('');
    const [branch,setBranch] = useState('');
    const [yearofpass,setYearofpass] = useState('');
    const [curcompany,setCurcompany] = useState('');
    const [designation,setDesignation] = useState('');
    const [experience,setExperience] = useState('');
    const [currentctc,setCurrentctc] = useState('');
    const [expectedctc,setExpectedctc] = useState('');
    const [noticeperiod,setNoticeperiod] = useState('');
    const [caddress,setCaddress] = useState('');
    const [paddress,setPaddress] = useState('');
    const [reference,setReference] = useState('');
    const [skill,setSkill] = useState('');
    const [id,setId] = useState('');
    const [jobfunction,setJobfunction]= useState('');
    const [area,setArea] =useState('');
    const [district,setDistrict]=useState('');
    const [state,setState]=useState('Karnataka');
    const [potentialcan,setPotentialcan]=useState('');
    const [prefLocation,setPrefLocation]=useState('');
    const [displayAuthor,setDisplayAuthor]=useState('');

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    
    useEffect(async()=>{
        if(loc.state.mode ==="EDIT"){
            setFirstname(loc.state.record.firstname);
            setLastName(loc.state.record.lastname);
            setMobile(loc.state.record.mobile);
            setEmail(loc.state.record.email);
            setDob(loc.state.record.dob);
            setGender(loc.state.record.gender);
            setEducation(loc.state.record.education);
            setBranch(loc.state.record.branch);
            setYearofpass(loc.state.record.yearofpass);
            setCurcompany(loc.state.record.curcompany);
            setDesignation(loc.state.record.designation);
            setExperience(loc.state.record.experience);
            setCurrentctc(loc.state.record.currentctc);
            setExpectedctc(loc.state.record.expectedctc);
            setNoticeperiod(loc.state.record.noticeperiod);
            setCaddress(loc.state.record.caddress);
            setPaddress(loc.state.record.paddress);
            setReference(loc.state.record.reference);
            setSkill(loc.state.record.skill);
            setId(loc.state.record.id);
            setArea(loc.state.record.area);
            setJobfunction(loc.state.record.jobfunction);
            setState(loc.state.record.state);
            setDistrict(loc.state.record.district);
            setPotentialcan(loc.state.record.potentialcan);
            setPrefLocation(loc.state.record.prefLocation);
            var url = "/users/" + loc.state.record.userid;
            var dt = await api.get(url,{headers:head});
            setDisplayAuthor("Created by : " + dt.data.name);
        }
       
    },[]);

    const saveRecord = event =>{
        var data = {
            "firstname": firstname,
            "lastname" : lastname,
            "mobile": mobile,
            "email": email,
            "dob": dob,
            "gender" : gender,
            "education" : education,
            "branch": branch,
            "year" : yearofpass,
            "curcompany" : curcompany,
            "designation" : designation,
            "experience" : experience,
            "currentctc" : currentctc,
            "expectedctc" : expectedctc,
            "noticeperiod" : noticeperiod,
            "caddress" : caddress,
            "paddress" : paddress,
            "reference" : reference,
            "id":id,
            "potentialcan":potentialcan,
            "district":district,
            "area":area,
            "jobfunction":jobfunction,
            "state": state,
            "prefLocation" : prefLocation
        };
        event.preventDefault();
        
        try{
            switch (loc.state.mode) {
                case "NEW":
                    api.post("/candidates",data,{headers:head});
                    break;
                case "EDIT":
                    api.put("/candidates",data,{headers:head});
                    break;
                default:
                    break;
            }
            event.currentTarget.disabled = true;
            event.preventDefault();
        }catch(e){

        }
        
    }

    return (
        <>
        <h5>Candidate Details</h5>
        <small>{displayAuthor}</small>
        <form>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Name</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-user"></i></div>
                        <input type="text" value={firstname} onChange={(e)=>setFirstname(e.target.value)} className="form-control" placeholder="First name"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Name</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-user"></i></div>
                        <input type="text" value={lastname} onChange={(e)=>setLastName(e.target.value)} className="form-control" placeholder="Last name"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Phone</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" value={mobile} onChange={(e)=>setMobile(e.target.value)} className="form-control" placeholder="Mobile Phone"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Email</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-at"></i></div>
                        <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" placeholder="Email"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >DOB</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-birthday-cake"></i></div>
                        <input type="date" value={dob} onChange={(e)=>setDob(e.target.value)} className="form-control" placeholder="DOB"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Gender</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-genderless"></i></div>
                        <select className='form-select ' value={gender} onChange={(e)=>setGender(e.target.value)}>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Education</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-university"></i></div>
                        <input type="text" value={education} onChange={(e)=>setEducation(e.target.value)} className="form-control" placeholder="Education"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Branch</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-book"></i></div>
                        <input type="text" value={branch} onChange={(e)=>setBranch(e.target.value)} className="form-control" placeholder="Branch"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Year Of Passing</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                        <input type="text" value={yearofpass} onChange={(e)=>setYearofpass(e.target.value)} className="form-control" placeholder="Year of Passing"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Current Company</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-briefcase"></i></div>
                        <input type="text" value={curcompany} onChange={(e)=>setCurcompany(e.target.value)} className="form-control" placeholder="Current Company"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Designation</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-id-badge"></i></div>
                        <input type="text" value={designation} onChange={(e)=>setDesignation(e.target.value)} className="form-control" placeholder="Designation"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Total Experience</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-server"></i></div>
                        <input type="text" value={experience} onChange={(e)=>setExperience(e.target.value)} className="form-control" placeholder="Total Experience"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Current CTC</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-paw"></i></div>
                        <input type="text" value={currentctc} onChange={(e)=>setCurrentctc(e.target.value)} className="form-control" placeholder="Current CTC"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Expected CTC</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-paw"></i></div>
                        <input type="text" value={expectedctc} onChange={(e)=>setExpectedctc(e.target.value)} className="form-control" placeholder="Expected CTC"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Notice Period</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-hourglass"></i></div>
                        <input type="text" value={noticeperiod} onChange={(e)=>setNoticeperiod(e.target.value)} className="form-control" placeholder="Notice Period"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Reference</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-edit"></i></div>
                        <input type="text" value={reference} onChange={(e)=>setReference(e.target.value)} className="form-control" placeholder="Reference"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >jobfunction</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-quote-left"></i></div>
                        <input type="text" value={jobfunction} onChange={(e)=>setJobfunction(e.target.value)} className="form-control" placeholder="Job Function"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >potentialcan</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-puzzle-piece"></i></div>
                        <input type="text" value={potentialcan} onChange={(e)=>setPotentialcan(e.target.value)} className="form-control" placeholder="Potential Employer"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >State</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-map"></i></div>
                        <select className='form-select ' value={state} onChange={(e)=>setState(e.target.value)}>
                            < option value="Andhra Pradesh">Andhra Pradesh</option>
                            < option value="Arunachal Pradesh">Arunachal Pradesh</option>
                            < option value="Assam">Assam</option>
                            < option value="Bihar">Bihar</option>
                            < option value="Chhattisgarh">Chhattisgarh</option>
                            < option value="Goa">Goa</option>
                            < option value="Gujarat">Gujarat</option>
                            < option value="Haryana">Haryana</option>
                            < option value="Himachal Pradesh">Himachal Pradesh</option>
                            < option value="Jharkhand">Jharkhand</option>
                            < option value="Karnataka">Karnataka</option>
                            < option value="Kerala">Kerala</option>
                            < option value="Madhya Pradesh">Madhya Pradesh</option>
                            < option value="Maharashtra">Maharashtra</option>
                            < option value="Manipur">Manipur</option>
                            < option value="Meghalaya">Meghalaya</option>
                            < option value="Mizoram">Mizoram</option>
                            < option value="Nagaland">Nagaland</option>
                            < option value="Odisha">Odisha</option>
                            < option value="Punjab">Punjab</option>
                            < option value="Rajasthan">Rajasthan</option>
                            < option value="Sikkim">Sikkim</option>
                            < option value="Tamil Nadu">Tamil Nadu</option>
                            < option value="Telangana">Telangana</option>
                            < option value="Tripura">Tripura</option>
                            < option value="Uttar Pradesh">Uttar Pradesh</option>
                            < option value="Uttarakhand">Uttarakhand</option>
                            < option value="West Bengal">West Bengal</option>
                        </select>  
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >district</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-map"></i></div>
                        <input type="text" value={district} onChange={(e)=>setDistrict(e.target.value)} className="form-control" placeholder="District"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >area</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-street-view"></i></div>
                        <input type="text" value={area} onChange={(e)=>setArea(e.target.value)} className="form-control" placeholder="Area"></input>
                    </div>
                </div>
    
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Pref Location</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-street-view"></i></div>
                        <input type="text" value={prefLocation} onChange={(e)=>setPrefLocation(e.target.value)} className="form-control" placeholder="Preferred Location"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col-auto">
                    <label className="visually-hidden" >Skill</label>
                    <div className="input-group">
                        <span className="input-group-text">Skill</span>
                        <textarea value={skill} onChange={(e)=>setSkill(e.target.value)} className="form-control" aria-label="With textarea"></textarea>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Cur Address</label>
                    <div className="input-group">
                        <span className="input-group-text">Cur. Address</span>
                        <textarea value={caddress} onChange={(e)=>setCaddress(e.target.value)} className="form-control" aria-label="With textarea"></textarea>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Per Address</label>
                    <div className="input-group">
                        <span className="input-group-text">Per. Address</span>
                        <textarea value={paddress} onChange={(e)=>setPaddress(e.target.value)} className="form-control" aria-label="With textarea"></textarea>
                    </div>
                </div>
            </div>
            
            <div className="row mt-2">
                <div className="col-auto"> 
                    <button className="btn btn-primary btn-sm" onClick={saveRecord}>Save</button>
                </div>
                <div className="col-auto"> 
                    <button className="btn btn-primary btn-sm" onClick={()=>{navigate("../../candidates")}}>Cancel</button>
                </div>
            </div>
        </form>
        </>
    )
}

export default Candidate;