import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Vacancy(props) {
    const navigate = useNavigate ();
    const [company,setCompany] = useState(0);
    const [department,setDepartment] = useState('');
    const [jobtitle,setJobtitle] = useState('');
    const [jobdate,setJobdate] = useState('');
    const [qualification,setQualification] = useState('');
    const [experience,setExperience] = useState('');
    const [noofjobs,setNoofjobs]= useState('');
    const [ctc,setCtc] = useState('');
    const [skill,setSkill]= useState('');

    const [clients,setClients] = useState([]);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/institutions";
                var dt = await api.get(url,{headers:head});
                setClients(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])


    const save=(e)=>{
        e.preventDefault();
        var data = {
            "jobtitle":jobtitle,
            "instid":company,
            "education":qualification,
            "experience":experience,
            "department":department,
            "jobdate": jobdate,
            "skill":skill,
            "noofjobs": noofjobs,
            "ctc":ctc
        }
        try{
            api.post("/jobs",data,{headers:head});
        }catch(e){
            
        }
        e.currentTarget.disabled = true;
    }

    return (
        <>
            <h5><i className="fa fa-briefcase"></i> New Vacancy</h5>
            <form>
                <div className="row p-2">
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Company</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-building"></i></div>
                            
                            <select className='form-select' value={company} onChange={(e)=>{setCompany(e.target.value)}}>
                                {
                                    clients.map((client,i)=>(
                                        <option value={client.id} key={i}>{client.name}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Department</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-university"></i></div>
                            <input type="text" value={department} onChange={(e)=>{setDepartment(e.target.value)}} className="form-control" placeholder="Department"></input>
                        </div>
                    </div>
                    <div className="col-auto mt-2">
                        <label className="visually-hidden" >JobTitle</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-user"></i></div>
                            <input type="text" value={jobtitle} onChange={(e)=>{setJobtitle(e.target.value)}} className="form-control" placeholder="Job Title"></input>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Qualification</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-certificate"></i></div>
                            <input type="text" value={qualification} onChange={(e)=>{setQualification(e.target.value)}} className="form-control" placeholder="Qualification"></input>
                        </div>
                    </div>
                                        
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Experience</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-cog"></i></div>
                            <input type="text" value={experience} onChange={(e)=>{setExperience(e.target.value)}} className="form-control" placeholder="Experience"></input>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >NoOfPosts</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-cog"></i></div>
                            <input type="text" value={noofjobs} onChange={(e)=>{setNoofjobs(e.target.value)}} className="form-control" placeholder="Number of posts"></input>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >CTC</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-database"></i></div>
                            <input type="text" value={ctc} onChange={(e)=>{setCtc(e.target.value)}} className="form-control" placeholder="CTC"></input>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Skill</label>
                        <div className="input-group">
                            <span className="input-group-text">Skill</span>
                            <textarea value={skill} onChange={(e)=>{setSkill(e.target.value)}} className="form-control" aria-label="With textarea"></textarea>
                        </div>
                    </div>
                    <div className="col-4 mt-2">
                        <label className="visually-hidden" >Contact No</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                            <input type="date" value={jobdate} onChange={(e)=>{setJobdate(e.target.value)}} className="form-control" placeholder="Managers Contact No"></input>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-4"> 
                        <button className="btn btn-sm btn-primary me-2" onClick={save}>Save</button>
                        <button className="btn btn-sm btn-primary me-2" onClick={()=>navigate("..")}>Cancel</button>
                    </div>
                </div>
            </form>
            
        </>
    );
}

export default Vacancy;