import React, { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Client() {
    const navigate = useNavigate ();
    const [name,setName] = useState('');
    const [contactno,setContactno] = useState('');
    const [companyurl,setCompanyurl] = useState('');
    const [address,setAddress] = useState('');
    const [id,setId] = useState('');
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    let loc = useLocation();
   
    const saveRecord = event =>{
        var data = {
            "name": name,
            "contactno": contactno,
            "companyurl": companyurl,
            "address": address,
            "id":id
        };
        try {
            switch (loc.state.mode) {
                case "NEW":
                   
                    api.post("/institutions", data,{headers:head});
                    break;
                case "EDIT":
                 
                    api.put("/institutions", data,{headers:head});
                    break;
                default:
                    break;
            }
            event.currentTarget.disabled = true;
            event.preventDefault();
        } catch (e) {
            
        }
        
        event.preventDefault();
    }

    useEffect(()=>{
        if(loc.state.mode ==="EDIT"){
            setName(loc.state.record.name);
            setContactno(loc.state.record.contactno);
            setCompanyurl(loc.state.record.companyurl);
            setAddress(loc.state.record.address)
            setId(loc.state.record.id);
        }
    },[])

    return (
        <>
            <h5>Institution Details</h5>
            <form>
                <div className="row p-2">
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Name</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-building"></i></div>
                            <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="Institution Name"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Web</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-globe"></i></div>
                            <input type="text" value={companyurl} onChange={(e)=>setCompanyurl(e.target.value)} className="form-control" placeholder="Company URL"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor="autoSizingInputGroup">Contact No</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-phone"></i></div>
                            <input type="text" value={contactno} onChange={(e)=>setContactno(e.target.value)} className="form-control" placeholder="Managers Contact No"></input>
                        </div>
                    </div>
                </div>
                <div className="row p-2">
                    <div className="col-auto">
                        <label htmlFor="autoSizingInputGroup">Address</label>
                        <textarea value={address} onChange={(e)=>setAddress(e.target.value)} cols={40} className="form-control" aria-label="With textarea"></textarea>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={saveRecord}>Save</button>
                    </div>
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={()=>{navigate("../../searchClient")}}>Cancel</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default Client;