import React, { useEffect,useState,useContext } from 'react';
import api from '../../api/Axiosapi';

var initFollowups = [
    {name:"VINOD S B",mobile:"9880381616",designation:"Design Engineer",experience:"4.5",id:"1"},
    {name:"BASAVARAJ S A",mobile:"9880381616",designation:"Design Engineer",experience:"4.2",id:"2"},
    {name:"Eeranna S",mobile:"9880381616",designation:"Design Engineer",experience:"4.0",id:"3"},
    {name:"MURALIDHAR REDDY AKKIMI",mobile:"9880381616",designation:"Automation Engineer",experience:"8.0",id:"4"}
]


function Frontdesk_main_TodaysFollowups()
{
    const [followups,setFollowups] = useState(initFollowups);
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("candidates/todaysfollowup/5",{headers:head});
                setFollowups(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])
    return(
    <div className="container-fluid pt-4 px-4">
        <div className="bg-light text-center rounded p-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h6 className="mb-0">Todays Followups</h6>
            </div>
            <div className="table-responsive">
                <table className="table text-start align-middle table-bordered table-hover mb-0">
                    <thead>
                        <tr className="text-dark">
                            <th scope="col">Name</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Experience</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            followups.map((followup,i)=>(
                                <tr key={i}>
                                    <td>{followup.name}</td>
                                    <td>{followup.mobile}</td>
                                    <td>{followup.designation}</td>
                                    <td>{followup.experience}</td>
                                    
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    );
}

export default Frontdesk_main_TodaysFollowups;
    