import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initContacts=[
    {name:"Dilip",designation:"Manager",devision:"Production",phone:"9845012345",email:"Dilip@gmail.com"}
]
function Contacts(props) {
    const navigate = useNavigate ();
    const [institute,setInstitute]= useState('');
    const [contacts,setContacts] = useState(initContacts);
    const [refresh,setRefresh]= useState(0);
    let loc = useLocation();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        setInstitute(loc.state.record.name)
        const url = "/institutions/"+ loc.state.record.id+"/contacts";
        const fetchData = async()=>{
            try{
                var dt = await api.get(url,{headers:head});
                setContacts(dt.data);
            }catch(e){
            }
        }
        if(!(loc.state.record.name === null)){
            fetchData();
        }
        
    },[refresh])

    return (
        <>
            <div className="row">
                <div className="col"><h5>Contacts @ {institute}</h5></div>
            </div>
            <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("newContact",{state:{mode:"NEW",instid:loc.state.record.id}})}}>Add Contact</button>
            <button className="btn btn-sm btn-primary me-2" onClick={()=>{setRefresh(refresh+1)}}>Refresh</button>
            <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate(-1)}}>Close</button>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Designation</th>
                        <th>Division</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        contacts.map((contact,i)=>(
                            <tr key={i}>
                                <td>{contact.name}</td>
                                <td>{contact.designation}</td>
                                <td>{contact.devision}</td>
                                <td>{contact.phone}</td>
                                <td>{contact.email}</td>
                                <td><button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("newContact",{state:{mode:"EDIT",record:contacts[i]}})}}>Edit</button></td>
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
            <Outlet />
        </>
    );
}

export default Contacts;