import React, { useEffect,useState } from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Tag(props) {
    const loc = useLocation();
    const navigate = useNavigate ();
    const [entityid,setEntityid] = useState('');
    const [category,setCategory] = useState('');
    const [tag,setTag] = useState('');
    const [access,setAccess] = useState('PRIVATE');
    const [tags,setTags] = useState([]);

    const token = sessionStorage.getItem('portalhr.token');
    const cuser = sessionStorage.getItem('portalhr.user');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    
    useEffect(()=>{
        setEntityid(loc.state.entityid);
        setCategory(loc.state.category);
        const search = {entityid:loc.state.entityid,entitytype:loc.state.category};
        const fetchData = async()=>{
            try{
                
                var dt = await api.post("/tags/entity",search,{headers:head});
                setTags(dt.data);
                console.log(JSON.stringify(dt.data));
                
            }catch(e){
            }
        }
        
        fetchData();
       
        
    },[]);

    const saveRecord = event =>{
        var data = {
            entityid:entityid,entitytype:category,access:access,tag:tag
        };
        api.post("/tags",data,{headers:head});
        event.currentTarget.disabled = true;
        event.preventDefault();
    }

    const deleteRecord = (event,id) =>{
        let url = "/tags/entity/"+ id;
        api.delete(url,{headers:head});
        event.currentTarget.disabled = true;
        event.preventDefault();
    }

    return (
        <>
            <h5>Tags</h5>
            <div className="row">
                <div className="col-auto">
                    <label className="visually-hidden" >Tag</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-tags"></i></div>
                        <input type="text" className="form-control" value={tag} onChange={(e)=>setTag(e.target.value)} placeholder="new tag name"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden" >Permission</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-lock"></i></div>
                        <select className='form-select ' value={access} onChange={(e)=>setAccess(e.target.value)}>
                            <option value="PRIVATE">PRIVATE</option>
                            <option value="PUBLIC">PUBLIC</option>
                        </select>
                    </div>
                </div>
                <div className="col-auto"> 
                    <button className="btn btn-primary btn-sm" onClick={saveRecord}>Apply</button>
                </div>
                <div className="col-auto"> 
                    <button className="btn btn-primary btn-sm" onClick={()=>{navigate(-1)}}>Cancel</button>
                </div>
            </div>
            <div className='row'>
                <div className= 'd-flex flex-row flex-wrap'>
                {
                    tags.map((t,i)=> {
                        if(t.access == "PUBLIC")
                        {
                            if(t.name == cuser){
                                return <div key={i} className='p-1 me-2 mt-2 text-danger'>{t.tag} <button type="button" className="btn btn-sm btn-close" onClick={e => deleteRecord(e,t.id)}></button></div>
                            }else{
                                return <div key={i} className='p-1 me-2 mt-2 text-danger'>{t.tag}</div>
                            }
                            
                        }else{
                            return <div key={i} className='p-1 me-2 mt-2 text-primary'>{t.tag} <button type="button" className="btn btn-sm btn-close" onClick={e => deleteRecord(e,t.id)}></button></div>
                        }
                        
                        })
                }
                </div>
            </div>
            
        </>
    );
}

export default Tag;