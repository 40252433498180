import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function Employee(props) {
    const navigate = useNavigate ();
    const [name,setName]= useState('');
    const [code,setCode]= useState('');
    const [phone,setPhone]= useState('');
    const [email,setEmail]= useState('');
    const [education,setEducation]= useState('');
    const [experience,setExperience]= useState('');
    const [gender,setGender]= useState('');
    const [dob,setDob]= useState('');
    const [fathername,setFathername]=useState('');
    const [fatherphno,setFatherphoneno]=useState('');
    const [mothername,setMothername]=useState('');
    const [motherphno,setMotherphoneno]=useState('');
    const [uan,setUan]= useState('');
    const [esi,setEsi]= useState('');
    const [adhar,setAdhar]= useState('');
    const [pan,setPan]= useState('');
    const [reportingto,setReportingto]= useState('');
    const [ctc,setCTC]= useState('');
    const [contractfrom,setContractfrom]= useState('');
    const [contractto,setContractto]= useState('');
    const [employername,setEmployername] = useState('');
    const [designation,setDesignation] = useState('');
    const [managername,setManagername] = useState('');
    const [managerphno,setManagerphno]= useState('');
    const [bank,setBank]= useState('');
    const [account,setAccount]= useState('');
    const [branch,setBranch]= useState('');
    const [ifsc,setIfsc]= useState('');
    const [caddress,setCaddress]= useState('');
    const [paddress,setPaddress]= useState('');
    const [id,setId]=useState('');
    const [bid,setBid]=useState('');
    const loc = useLocation();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const save = (e)=>{
        var data = {
            employeeno:code,
            name:name,
            phoneno:phone,
            email:email,
            education:education,
            experience:experience,
            gender:gender,
            dob:dob,
            fathername:fathername,
            fatherphno:fatherphno,
            mothername:mothername,
            motherphno:motherphno,
            uanno:uan,
            esino:esi,
            adharno:adhar,
            panno:pan,
            reportingto:reportingto,
            ctc:ctc,
            contractfrom:contractfrom,
            contractto:contractto,
            employername:employername,
            designation:designation,
            managername:managername,
            managerphno:managerphno,
            caddress:caddress,
            paddress:paddress,
            bankname: bank,
            accountno:account,
            branchname:branch,
            ifsc:ifsc,
            id:id,
            bid:bid
        };
      
        switch (loc.state.mode) {
            case "NEW":
                api.post('/employees',data,{headers:head});
                break;
            case "EDIT":
                api.put("/employees",data,{headers:head});
                break;

            default:
                break;
        }
        e.currentTarget.disabled = true;
        e.preventDefault();
    }

    useEffect(()=>{
        if(loc.state.mode ==="EDIT"){
           
            setName(loc.state.record.name);
            setCode(loc.state.record.employeeno);
            setPhone(loc.state.record.phoneno);
            setEmail(loc.state.record.email);
            setEducation(loc.state.record.education);
            setExperience(loc.state.record.experience);
            setGender(loc.state.record.gender);
            setDob(loc.state.record.dob);
            setFathername(loc.state.record.fathername);
            setFatherphoneno(loc.state.record.fatherphno);
            setMothername(loc.state.record.mothername);
            setMotherphoneno(loc.state.record.motherphno);
            setUan(loc.state.record.uanno);
            setEsi(loc.state.record.esino);
            setAdhar(loc.state.record.adharno);
            setPan(loc.state.record.panno);
            setReportingto(loc.state.record.reportingto);
            setCTC(loc.state.record.ctc);
            setContractfrom(loc.state.record.contractfrom);
            setContractto(loc.state.record.contractto);
            setEmployername(loc.state.record.employername);
            setDesignation(loc.state.record.designation);
            setManagername(loc.state.record.managername);
            setManagerphno(loc.state.record.managerphno);
            setCaddress(loc.state.record.caddress);
            setPaddress(loc.state.record.paddress);
            setId(loc.state.record.id);
            
            const fetchData = async()=>{
                try{
                    var criteria = {id:loc.state.record.id};
                    var dt = await api.post("/employees/getBankdetails",criteria,{headers:head});
                    setBank(dt.data.bankname);
                    setAccount(dt.data.accountno);
                    setBranch(dt.data.branchname);
                    setIfsc(dt.data.ifsc);
                    setBid(dt.data.id);
                }catch(e){
                }
            }
            if(!(loc.state.record.id === null)){
                fetchData();
            }
        }
    },[])


    return (
        <>
            <h5>Employee details</h5>

            <Tabs defaultActiveKey="basic" id="uncontrolled-tab-example" className="mb-3" transition={false}>
                <Tab eventKey="basic" title="Basic">
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>Name</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-user"></i></div>
                                <input type="text" value={name} onChange={(e)=>{setName(e.target.value)}} className="form-control" placeholder="Employee Name"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Code</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-ellipsis-h"></i></div>
                                <input type="text" value={code} onChange={(e)=>{setCode(e.target.value)}} className="form-control" placeholder="Employee Code"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Phone No</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-phone"></i></div>
                                <input type="number" value={phone} onChange={(e)=>{setPhone(e.target.value)}} className="form-control" placeholder="Phone Number"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Email</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-at"></i></div>
                                <input type="email" value={email} onChange={(e)=>{setEmail(e.target.value)}} className="form-control" placeholder="Email"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row p-2">
                    <div className="col-auto">
                        <label>Education</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-graduation-cap"></i></div>
                            <input type="text" value={education} onChange={(e)=>{setEducation(e.target.value)}} className="form-control" placeholder="Education"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Experience</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-cogs"></i></div>
                            <input type="text" value={experience} onChange={(e)=>{setExperience(e.target.value)}} className="form-control" placeholder="Experience"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Gender</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-at"></i></div>
                            <input type="text" value={gender} onChange={(e)=>{setGender(e.target.value)}} className="form-control" placeholder="Gender"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>DOB</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                            <input type="date" value={dob} onChange={(e)=>{setDob(e.target.value)}} className="form-control" ></input>
                        </div>
                    </div>
                    </div>
                    <div className="row p-2">
                    <div className="col-auto">
                        <label>Fathers Name</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-male"></i></div>
                            <input type="text" value={fathername} onChange={(e)=>{setFathername(e.target.value)}} className="form-control" placeholder="Father Name"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Father Phoneno</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-phone"></i></div>
                            <input type="text" value={fatherphno} onChange={(e)=>{setFatherphoneno(e.target.value)}} className="form-control" placeholder="Fathers Phoneno"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Mothers Name</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-female"></i></div>
                            <input type="email" value={mothername} onChange={(e)=>{setMothername(e.target.value)}} className="form-control" placeholder="Gender"></input>
                        </div>
                    </div>
                    <div className="col-auto">
                        <label>Mothers Phoneno</label>
                        <div className="input-group input-group-sm">
                            <div className="input-group-text"><i className="fa fa-phone"></i></div>
                            <input type="text" value={motherphno} onChange={(e)=>{setMotherphoneno(e.target.value)}} className="form-control" ></input>
                        </div>
                    </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>Current Address</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-address-book"></i></div>
                                <textarea  value={caddress} onChange={(e)=>{setCaddress(e.target.value)}} cols={30} className="form-control" placeholder="Current Address"></textarea>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Permanent Address</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-address-book"></i></div>
                                <textarea value={paddress} onChange={(e)=>{setPaddress(e.target.value)}} cols={30} className="form-control" placeholder="Permanent Address"></textarea>
                            </div>
                        </div>
                    </div>
                </Tab>
                <Tab eventKey="contract" title="Contract">
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>UAN Number</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                <input type="text" value={uan} onChange={(e)=>{setUan(e.target.value)}} className="form-control" placeholder="UAN Number" ></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>ESI Number</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                <input type="text" value={esi} onChange={(e)=>{setEsi(e.target.value)}} className="form-control" placeholder="ESI Number"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Adhar Number</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-blind"></i></div>
                                <input type="text" value={adhar} onChange={(e)=>{setAdhar(e.target.value)}} className="form-control" placeholder="Adhar Number"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>PAN Number</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-id-card"></i></div>
                                <input type="email" value={pan} onChange={(e)=>{setPan(e.target.value)}} className="form-control" placeholder="PAN Number"></input>
                            </div>
                        </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>Employer</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-industry"></i></div>
                                <input type="text" value={employername} onChange={(e)=>{setEmployername(e.target.value)}} className="form-control" placeholder="Reporting Officier"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Designation</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-id-badge"></i></div>
                                <input type="text" value={designation} onChange={(e)=>{setDesignation(e.target.value)}} className="form-control" placeholder="CTC"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Manager</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-user"></i></div>
                                <input type="text" value={managername} onChange={(e)=>{setManagername(e.target.value)}} className="form-control" ></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Manager Phone</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-phone"></i></div>
                                <input type="number" value={managerphno} onChange={(e)=>{setManagerphno(e.target.value)}} className="form-control" ></input>
                            </div>
                        </div>
                    </div>
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>Reporting To</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-blind"></i></div>
                                <input type="text" value={reportingto} onChange={(e)=>{setReportingto(e.target.value)}} className="form-control" placeholder="Reporting Officier"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>CTC</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-tint"></i></div>
                                <input type="number" value={ctc} onChange={(e)=>{setCTC(e.target.value)}} className="form-control" placeholder="CTC"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Contract from</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                <input type="date" value={contractfrom} onChange={(e)=>{setContractfrom(e.target.value)}} className="form-control" ></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Contract to</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-calendar"></i></div>
                                <input type="date" value={contractto} onChange={(e)=>{setContractto(e.target.value)}} className="form-control" ></input>
                            </div>
                        </div>
                    </div>
                    
                </Tab>
                <Tab eventKey="bank" title="Bank Details">
                    <div className="row p-2">
                        <div className="col-auto">
                            <label>Bank Name</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-archive"></i></div>
                                <input type="text" value={bank} onChange={(e)=>{setBank(e.target.value)}} className="form-control" placeholder="Bank Name" ></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Account Number</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-barcode"></i></div>
                                <input type="text" value={account} onChange={(e)=>{setAccount(e.target.value)}} className="form-control" placeholder="Account Number"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>Branch Name</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-blind"></i></div>
                                <input type="text" value={branch} onChange={(e)=>{setBranch(e.target.value)}} className="form-control" placeholder="Branch Name"></input>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label>IFSC Code</label>
                            <div className="input-group input-group-sm">
                                <div className="input-group-text"><i className="fa fa-ellipsis-h"></i></div>
                                <input type="text" value={ifsc} onChange={(e)=>{setIfsc(e.target.value)}} className="form-control" placeholder="IFSC Code"></input>
                            </div>
                        </div>
                    </div>
                    
                </Tab>
            </Tabs>
            <button className="btn btn-sm btn-primary me-2" onClick={save}>Save</button> 
            <button className="btn btn-sm btn-primary me-2" onClick={(e)=>{navigate(-1)}}>Cancel</button> 
        </>
    );
}

export default Employee;