import React from 'react';
import { Outlet } from 'react-router-dom';
import Supervisor_sidebar from './Supervisor_sidebar';
import './app.css';
import Application_navbar from './Application_navbar';

function Superwiserhome() {
    return (
        <>
            <Supervisor_sidebar />
            <div className="content">
                <Application_navbar />
                <Outlet />
            </div>
        </>
    )
}

export default Superwiserhome;