import React, { useState } from 'react';
import { useNavigate, createSearchParams  } from 'react-router-dom';

function EmployeeSearch(props) {
    const navigate = useNavigate ();
    const [name,setName] = useState('');
    const [phone,setPhone] = useState('');
    const [code,setCode] = useState('');

    const search =(e)=>{
        e.preventDefault();
        navigate({pathname :"search",
                    search: createSearchParams({name:name,phone:phone,code:code}).toString()
                })
    }

    return (
        <>
            <div className="row">
                <div className="col-auto">
                    <label className="visually-hidden">Name</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-user"></i></div>
                        <input type="text" className="form-control" value={name} onChange={(e)=>{setName(e.target.value)}} placeholder="Name"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden">Phone</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" className="form-control" value={phone} onChange={(e)=>{setPhone(e.target.value)}} placeholder="Mobile phoneno"></input>
                    </div>
                </div>
                <div className="col-auto">
                    <label className="visually-hidden">Code</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-ellipsis-h "></i></div>
                        <input type="text" className="form-control" value={code} onChange={(e)=>{setCode(e.target.value)}} placeholder="Code"></input>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-auto"> 
                    <button className="btn btn-sm btn-primary" onClick={()=>{navigate("newEmployee",{state:{mode:"NEW"}})}}>New</button>
                </div>
                <div className="col-auto"> 
                    <button className="btn btn-sm btn-primary" onClick={search}>Search</button>
                </div>
            </div> 
        </>
    );
}

export default EmployeeSearch;