import React, { useState } from 'react';
import { useNavigate , createSearchParams, Outlet } from 'react-router-dom';



function ClientSearch() {
    let navigate = useNavigate ();
    const [name,setName] = useState('');

    const gotoNewClient =(e)=>{
        e.preventDefault();
        navigate("newClient",{state:{mode:"NEW"}});
    }
    const clientSearch =(e)=>{
        e.preventDefault();
        navigate({pathname :"searchClient",
                  search: createSearchParams({name:name}).toString()
                })
    }


    return (
        <>
            <form className="mt-2 gy-2 gx-3 align-items-center">
                <div className="row">
                    <div className="col-auto">
                        <label className="visually-hidden" htmlFor ="autoSizingInputGroup">Name</label>
                        <div className="input-group">
                            <div className="input-group-text"><i className="fa fa-building"></i></div>
                            <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="Institution Name"></input>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-auto"> 
                        <button className="btn btn-sm btn-primary" onClick={gotoNewClient}>New</button>
                    </div>
                    <div className="col-auto"> 
                        <button className="btn btn-sm  btn-primary" onClick={clientSearch}>Search</button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ClientSearch;