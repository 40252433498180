import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate ,useSearchParams } from 'react-router-dom';
import api from '../../api/Axiosapi';


var initClients=[
    {name:"",id:""}
];

function ClientList() {
    const navigate = useNavigate ();
    const [clients,setClients] = useState(initClients);
    const [searchParams] = useSearchParams();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};


    useEffect(()=>{
        const search = {name:searchParams.get("name")};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/institutions/search",search,{headers:head});
                setClients(dt.data);
            }catch(e){
            }
        }
        if(!(search.name === null)){
            fetchData();
        }
        
    },[searchParams])

    /*
    <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("clientContacts",{state:{record:clients[i]}})}}>Contacts</button>
    */
    return (
        <>
            <div className="row ms-1">
                <table className="table" >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            clients.map((client,i)=>(
                                <tr key={i}>
                                    <td>{client.name}</td>
                                    <td>
                                    <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("clientVacancies",{state:{record:clients[i]}})}}>Vacancy</button>
                                    <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("clientDetails",{state:{mode:"EDIT",record:clients[i]}})}}>Detail</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                    
                </table>
            </div>

            <Outlet />
        </>
        
    )
}
export default ClientList;