import React from 'react';
import { Outlet } from 'react-router-dom';
import ClientSearch from '../components/Client/ClientSearch';

function FrontdeskInstitutions() {
    
    return (
        <>
            <h5>Institution Search</h5>
            <ClientSearch />
            <br></br>
            <Outlet />
        </>
    )
}

export default FrontdeskInstitutions;