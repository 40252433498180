import React,{useContext} from "react";
import write from './login-draw2.webp';
import api from '../../api/Axiosapi';
import AuthContext from "../../context/AuthContext";

import { useNavigate, useLocation } from 'react-router-dom';
import { useState,useRef } from "react";

function getHomepage(uname){
    var retval =""
    switch (uname) {
        case "LEAD":
            retval ="/lead"
            break;
        case "MANAGER":
            retval ="/manager"
            break;
        case "SUPERVISOR":
            retval ="/supervisor"
            break;
        case "FRONTDESK":
            retval ="/frontdesk"
            break;
        default:
            break;
    }
    return retval;
}

function Login() {

    const location = useLocation();

    const [username,setUsername] = useState('');
    const [pwd,setPwd] = useState('');
    
    const navigate = useNavigate();
    const user = useContext(AuthContext);
    const setUser = user[1];

    const loginClickEventHandler=(e)=>{
        var url="/login"

        const fetchData = async()=>{
            try{
                var criteria = {name:username,password:pwd};
                var dt = await api.post(url,criteria);
                if(dt.data.status=200){
                    sessionStorage.setItem("portalhr.user",username);
                    sessionStorage.setItem("portalhr.token",dt.data.token);
                    sessionStorage.setItem("portalhr.role",dt.data.role);
                    let userDetails = {"name":"defName","token":"defToken","role":"defRole"};
                    userDetails.name = username;
                    userDetails.role = dt.data.role;
                    userDetails.token = dt.data.token;
                    setUser(userDetails);
                    navigate(getHomepage(dt.data.role));
                }else{
                    sessionStorage.removeItem("portalhr.user");
                    sessionStorage.removeItem("portalhr.token");
                    sessionStorage.removeItem("portalhr.role");
                }
                
            }catch(e){
                
            }
        }
        fetchData();
        
    }

    return (
        <>
        <br></br><br></br><br></br><br></br>
        <div className="mt-5 w-50 mx-auto">
        <div className=" d-flex  h-80">    
            <div className="col ">
                <img src={write} className="img-fluid" alt="Sample image" /> 
            </div>
            <div className="col">
                <div className="form-outline mb-4">
                <label className="form-label" htmlFor="username">User Name</label>
                <input  type="text" 
                        required 
                        autoComplete="off"
                        value={username}
                        className="form-control "
                        onChange={(e)=> setUsername(e.target.value)}
                        placeholder="Enter User Name" />
                    
                </div>
                <div className="form-outline mb-3">
                    <label className="form-label" htmlFor="pass">Password</label>
                    <input type="password" 
                        id="pass" 
                        className="form-control "
                        value={pwd}
                        onChange={(e)=> setPwd(e.target.value)}
                        placeholder="Enter password" 
                        required
                        />
                    
                </div>
                <div className="mb-3">
                    <button className="btn btn-primary btn-sm" onClick={loginClickEventHandler} >Login</button>
                </div> 
            </div>    
        </div>
        </div>
        </>
    )
  }
  
  export default Login;