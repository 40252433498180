import React, { useEffect, useState } from 'react';
import interviewAvatar from './interview.png';
import api from '../../api/Axiosapi';


var initCanList=[
    {name:"Prakash S N",time:"23-4-2022 @ 2.30PM",employer:"Bosch",post:"Production Engineer"},
    {name:"Balakrishnan Durairajan",time:"23-4-2022 @ 3.30PM",employer:"Wipro",post:"Supervisor"},
    {name:"Daas P",time:"23-4-2022 @ 1.30PM",employer:"Mayon and Mayon",post:"Manager"},
    {name:"VIJAY SANKAR S",time:"23-4-2022 @ 5.00PM",employer:"Stump and Shule",post:"Manager"}
    
]

function Frontdesk_main_widget_Interviews() {
    const [interviewList,setInterviewList] = useState(initCanList);

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var dt = await api.get("interviews/todaysinterview/5",{headers:head});
                
                setInterviewList(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <div className="col-sm-12 col-md-6 col-xl-4">
            <div className="h-100 bg-light rounded p-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="mb-0">Interviews</h6>
                    
                </div>
                {
                    interviewList.map((iview,i)=>
                        (
                            <div key={i} className="d-flex align-items-center border-bottom py-3">
                            <img className="rounded-circle flex-shrink-0 femaleAvatar" src={interviewAvatar} alt="" />
                            <div key={i} className="w-100 ms-3">
                                <div className="d-flex w-100 justify-content-between">
                                    <h6 className="mb-0">{iview.name}</h6>
                                    <small>{iview.time}</small>
                                </div>
                                <span><small>{iview.employer} - {iview.post}</small></span>
                            </div>
                            </div>
                        )
                    )
                }
                
            </div>
        </div>
    );
}

export default Frontdesk_main_widget_Interviews;