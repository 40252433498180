import React, { useState,useEffect } from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';


function InterviewDetails(props) {
    const navigate = useNavigate ();
    const loc = useLocation();
    const [employer,setEmployer] = useState('');
    const [jobtitle,setJobtitle] = useState('');
    const [skill,setSkill] = useState('');
    const [manager,setManager] = useState('');
    const [mgrphone,setMgrphone] = useState('');
    const [candidate,setCandidate]= useState('');
    const [mobile,setMobile]= useState('');
    const [education,setEducation]= useState('');
    const [designation,setDesignation]= useState('');
    const [experience,setExperience]= useState('');
    const [intvDate,setIntvdate]= useState('');
    const [intvID,setIntvID]= useState('');
    const [actionType,setActionType] = useState('');
    const [newintDate,setNewintDate] = useState('');
    const [note,setNote]= useState("");
    const [canid,setCanid] = useState('');
    const [intvnote,setIntvnote] = useState('');
   
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        setEmployer(loc.state.record.employername);
        setJobtitle(loc.state.record.jobtitle);
        setManager(loc.state.record.manager);
        setMgrphone(loc.state.record.mgrphone);
        setSkill(loc.state.record.skill);
        setCandidate(loc.state.record.firstname + " " + loc.state.record.lastname );
        setMobile(loc.state.record.mobile);
        setEducation(loc.state.record.education);
        setDesignation(loc.state.record.designation);
        setExperience(loc.state.record.experience);
        setIntvdate(loc.state.record.intvdate);
        setIntvID(loc.state.record.intvid);
        setCanid(loc.state.record.canid);
        setIntvnote(loc.state.record.note);

    },[])

    const candidateSelected = (e)=>{
        setActionType("SELECTED");
    }

    const candidateRejected = (e)=>{
        setActionType("REJECTED");
    }

    const candidateOnhold = (e)=>{
        setActionType("ON_HOLD");
    }

    const candidateOffered = (e)=>{
        setActionType("OFFERED");
    }

    const candidateJoined = (e)=>{
        setActionType("JOINED");
    }

    const interviewRescheduled = (e)=>{
        setActionType("RESCHEDULED");
    }

    const save = (e)=>{
        e.currentTarget.disabled = true;
        e.preventDefault();
        var data={};
        var url="";
        switch(actionType){
            case "SELECTED":
                url = "/interviews/"+intvID+"/selected";
                data={"note":note,"canid":canid};
                break;

            case "REJECTED":
                url="/interviews/"+intvID+"/rejected";
                data={"note":note,"canid":canid};
                break;
            
            case "ON_HOLD":
                url="/interviews/"+intvID+"/onhold";
                data={"note":note,"canid":canid};
                break;
            
            case "OFFERED":
                url="/interviews/"+intvID+"/offered";
                data={"note":note,"canid":canid};
                break;

            case "RESCHEDULED":
                url = "/interviews/"+intvID+"/reschedule";
                data = {"intvdate":newintDate,"note":note};
                break;

            case "JOINED":
                url = "/interviews/"+intvID+"/joined";
                data = {"intvdate":newintDate,"note":note};
                break;
        }
        api.put(url, data,{headers:head});
    }

    return (
        <>
            <div className="row pt-2">
                <h5>Interview Details</h5>
                <div className="row ">
                    <div className="col">
                        <div className="card h-100">
                            <div className="card-header">
                            {employer}
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{jobtitle}</h5>
                                <small>
                                 {skill}
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card h-100">
                            <div className="card-header">
                                {candidate}
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{designation}</h5>
                                <small>
                                <ul className="">
                                    <li>Education : {education}</li>
                                    <li>Experience : {experience}</li>
                                    <li>Phone : {mobile}</li>
                                </ul>
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <div className="card-header">
                                Appointment Details
                            </div>
                            <div className="card-body">
                                <small>
                                    <ul>
                                        <li>Date : {intvDate}</li>
                                        <li>Manager : {manager} - ({mgrphone})</li>
                                    </ul>
                                </small>
                                <h6>Comment</h6>
                                <p>{intvnote}</p>
                                <hr></hr>
                                <h6>Action</h6>
                                <div className='row'>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={candidateSelected}></input>
                                        <label class="form-check-label" >SELECTED</label>
                                    </div>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={candidateRejected}></input>
                                        <label class="form-check-label" >REJECTED</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={candidateOnhold}></input>
                                        <label class="form-check-label" >ON-HOLD</label>
                                    </div>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={candidateOffered}></input>
                                        <label class="form-check-label" >OFFERED</label>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={interviewRescheduled}></input>
                                        <label class="form-check-label" for="inlineRadio3">RESCHEDULED</label>
                                    </div>
                                    <div class="col form-check form-check-inline">
                                        <input class="form-check-input" type="radio" name="action" value={actionType} onClick={candidateJoined}></input>
                                        <label class="form-check-label" for="inlineRadio3">JOINED</label>
                                    </div>
                                    {
                                        (actionType ==="RESCHEDULED" || actionType==="JOINED") &&
                                        <div className="col input-group">
                                            
                                            <input type="datetime-local" className="form-control" value={newintDate} onChange={(e)=>setNewintDate(e.target.value)}></input>
                                        </div>
                                    }
                                </div>
                                
                                <h6>Comment</h6>
                                <textarea  className="form-control" value={note} onChange={(e)=>setNote(e.target.value)}></textarea>
                            </div>
                            <div className="card-footer text-muted mt-2">
                                <div className="row">
                                    <div className='col-3'>
                                        <button className="btn btn-sm btn-primary " onClick={save}>Submit</button>
                                    </div> 
                                    <div className='col-3'>
                                        <button className="btn btn-sm btn-primary ms-3 " onClick={()=>{navigate(-1)}}>Close</button>
                                    </div>                               
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            
        </>
    );
}

export default InterviewDetails;