import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,useSearchParams } from 'react-router-dom';
import api from '../../api/Axiosapi';


var initCanList = [
    {firstname:"",lastname:"", education:"",mobile:"",email:"",designation:"",experience:"",currentctc:"",id:""}
]


function CandidateList(Route) {
    const navigate = useNavigate ();
    const [searchParams] = useSearchParams();
    const [candidates,setCandidates]=useState(initCanList);
    const token = sessionStorage.getItem("portalhr.token");
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const search = {name:searchParams.get("name"),mobile:searchParams.get("mobile"),education:searchParams.get("education"),
        state:searchParams.get("state"),area:searchParams.get("area"),district:searchParams.get("district"),
        experience:searchParams.get("experience"),potentialcan:searchParams.get("potentialcan"),jobfunction:searchParams.get("jobfunction"),
        prefLocation:searchParams.get("prefLocation")};
        console.log(JSON.stringify(search));
        const fetchData = async()=>{
            try{
                var dt = await api.post("/candidates/search",search,{headers:head});
                setCandidates(dt.data);
               
            }catch(e){
            }
        }
        
        if(!(search.name === null && search.mobile === null && search.education===null&& search.jobfunction === null && search.area === null && search.district===null && search.potentialcan===null)){
            fetchData();
        }
        
    },[searchParams])

    return (
        <>
            <div className="row mt-2">
                <table className="table" >
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Education</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Designation</th>
                            <th>Exp</th>
                            <th>CTC</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            candidates.map((can,i)=>(
                                <tr key={i}>
                                    <td>{can.firstname} {can.lastname}</td>
                                    <td>{can.education}</td>
                                    <td>{can.mobile}</td>
                                    <td>{can.email}</td>
                                    <td>{can.designation}</td>
                                    <td>{can.experience}</td>
                                    <td>{can.currentctc}</td>
                                    <td>{can.status}</td>
                                    <td>
                                        <button className="btn btn-sm btn-primary  me-2  mt-2" onClick={()=>{ console.log(JSON.stringify(candidates[i])); navigate("../newCandidate",{state:{mode:"EDIT",record:candidates[i]}})}}>Edit</button>
                                        <button className="btn btn-sm btn-primary me-2   mt-2" onClick={()=>{ navigate("followup",{state:{candidateid:candidates[i].id}})}}>Follow up</button>
                                        {
                                            '1'=='FRONTDESK_COMMENTTHIS' &&
                                            <button className="btn btn-sm btn-primary me-2   mt-2" onClick={()=>{ navigate("tag",{state:{entityid:candidates[i].id,category:"candidates"}})}}>Tags</button>
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            <div className="row mt-2">
                <Outlet />
            </div>
        </>
        
    )
}

export default CandidateList;