import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,useSearchParams  } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initReminders=[
    {firstname:"Mohan",lastname:"",education:"BE",mobile:"9845012345",email:"Mohan@gmail.com",followupdate:"25-09-2022"}
]
function ReminderList(props) {
    const navigate = useNavigate ();
    const [reminders,setReminders] = useState(initReminders);
    const [searchParams] = useSearchParams();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};
    useEffect(()=>{
        const search = {mobile:searchParams.get("phone"),fromdate: searchParams.get("fromdate"),todate:searchParams.get("todate")};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/followups/search",search,{headers:head});
                setReminders(dt.data);
            }catch(e){
            }
        }
        if(!(search.mobile === null && search.fromdate === null && search.todate === null)){
            fetchData();
        }
        
    },[searchParams])

    return (
        <>
        <div className="row ms-1">
            <h5>Search Result</h5>
            <table className='table' >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Education</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Followup Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reminders.map((reminder,i)=>(
                            <tr key={i}>
                                <td> {reminder.firstname} {reminder.lastname} - {reminder.id} </td>
                                <td> {reminder.education} </td>
                                <td> {reminder.mobile} </td>
                                <td> {reminder.email} </td>
                                <td> {reminder.followupdate}</td>
                                <td>
                                    <button className="btn btn-sm btn-primary me-2" onClick={()=>{navigate("followupByCandidate",{state:{canid:reminder.id}})}}>Followups</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        <div className="container-fluid pt-4 px-4">
            <div className="row g-4">
                <Outlet />
            </div>
        </div>
        </>
    );
}

export default ReminderList;