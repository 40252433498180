import React, { useEffect, useState } from 'react';

function VisualCalendarDay(props) {
    
    const [disday,setDisday] = useState('');
    const [dismonth,setDismonth] = useState('');
    const [distime,setDistime] = useState('');

    useEffect(()=>{
        const eventDate = props.eventdate;
        console.log(props.eventdate);
        const displayDate = new Date(eventDate);
        const month = displayDate.getMonth()+1;
        switch (month) {
            case 1:
                setDismonth("JAN");
                break;
            case 2:
                setDismonth("FEB");
                break;
            case 3:
                setDismonth("MAR");
                break;
            case 4:
                setDismonth("APR");
                break;
            case 5:
                setDismonth("MAY");
                break;
            case 6:
                setDismonth("JUN");
                break;
            case 7:
                setDismonth("JUL");
                break;
            case 8:
                setDismonth("AUG");
                break;
            case 9:
                setDismonth("SEP");
                break;
            case 10:
                setDismonth("OCT");
                break;
            case 11:
                setDismonth("NOV");
                break;
            case 12:
                setDismonth("DEC");
                break;
            default:
                break;
        }
        
        setDisday(displayDate.getDate());
        setDistime(displayDate.getHours()+" : " + displayDate.getMinutes());
    },[])

    return (
        <div className='border shadow-lg bg-body rounded-top'>
            <div className='border border-bottom bg-danger rounded-top text-white text-center fw-bold'><span className='m-2'> {dismonth} </span></div>
            <div className='border-bottom border-dark border-4 text-center fw-bold'>{disday}</div>
            <div className='text-center'><i className="fa fa-clock "></i> {distime}</div>
        </div>
    );
}

export default VisualCalendarDay;