import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate,useSearchParams  } from 'react-router-dom';
import api from '../../api/Axiosapi';

var initEmployees =[
    {name:"S Niveshkumar",employeeno:"2868",phoneno:"745006082801",email:"kumar@gmail.com",designation:"Manager",employername:"somebody",reportingto:"Basavaraj"}
]
function EmployeeList(props) {
    const navigate = useNavigate ();
    const [employees,setEmployees] = useState(initEmployees)
    const [searchParams] = useSearchParams();

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const search = {name:searchParams.get("name"),phone:searchParams.get("phone"),code:searchParams.get("code")};
        const fetchData = async()=>{
            try{
                var dt = await api.post("/employees/search",search,{headers:head});
                console.log(JSON.stringify(search));
                setEmployees(dt.data);
            }catch(e){
            }
        }
        if(!(search.name === null && search.code===null & search.phone===null)){
            fetchData();
        }
        
    },[searchParams])

    return (
        <>
         <div >
            <table className='table table-sm'>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Code</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Designation</th>
                        <th>Employer</th>
                        <th>Report to</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        employees.map((emp,i)=>(
                            <tr key={i}>
                                <td>{emp.name}</td>
                                <td>{emp.employeeno}</td>
                                <td>{emp.phoneno} </td>
                                <td>{emp.email}</td>
                                <td>{emp.designation}</td>
                                <td>{emp.employername}</td>
                                <td>{emp.reportingto}</td>
                                <td><button className="btn btn-sm btn-primary" onClick={()=>{navigate("../newEmployee",{state:{mode:"EDIT",record:emp}})}}>Details</button></td>
                            </tr>
                        ))
                    }
                    
                </tbody>
            </table>
         </div>
            <Outlet />
        </>
    );
}

export default EmployeeList;