import React, { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import api from '../../api/Axiosapi';


var initVacancies=[
    {
        id:1,
        jobtitle:"Mechanical Design Engineer",
        instid:6,
        education:"MBA",
        experience: 6,
        department:"R&D",
        jobDate:"2022-10-13",
        noofjobs:1,
        status:"CREATED",
        assignedTo: 101,
        CTC:5.5,
        name:"AssignedTo",
        skill:""
    },
    {
        id:1,
        jobtitle:"Design Engineer",
        instid:6,
        education:"BE",
        experience: 16,
        department:"Production",
        jobDate:"2022-10-13",
        noofjobs:1,
        status:"CREATED",
        assignedTo: 101,
        CTC:5.5,
        name:"AssignedTo",
        skill:""
    }
]

function Vacancies(props) {
    const navigate = useNavigate ();
    const [vacancies,setVacancies]=useState(initVacancies);
    const loc = useLocation();
    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    useEffect(()=>{
        const fetchData = async()=>{
            try{
                var url = "/institutions/"+ loc.state.record.id+"/jobs";
                var dt = await api.get(url,{headers:head});
                setVacancies(dt.data);
            }catch(e){
            }
        }
        fetchData();
    },[])

    return (
        <>
        <div className="p-2">
            <div className="row">
                <div className="col"><h5>Vacancies List @ {loc.state.record.name}</h5></div>
            </div>
            
            <div className="row row-cols-1 row-cols-md-3 g-4">
                {
                    vacancies.map((vacancy,id)=>(
                        <div className="col">
                            <div className="card h-100">
                                <div className="card-header">
                                    {vacancy.jobtitle}
                                </div>
                                <div className="card-body">
                                    <div class="row">
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Education</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.education}</span>
                                            </small>
                                        </div>
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Experience</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.experience}</span>
                                            </small>
                                        </div>
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Department</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.department}</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Job Date</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.jobDate}</span>
                                            </small>
                                        </div>
                                        <div className="col">
                                            <span className=" border-danger border-bottom "># Positions</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.noofjobs}</span>
                                            </small>
                                        </div>
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Status</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.status}</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">CTC</span><br></br>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.CTC}</span>
                                            </small>
                                        </div>
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Assigned</span>
                                            <small>
                                                <span className="fw-bolder"> {vacancy.name}</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div className="col">
                                            <span className=" border-danger border-bottom ">Skill</span>
                                            <small>
                                                <div className="text-wrap" >
                                                    {vacancy.skill}
                                                </div>
                                            </small>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <div className="row">
                <Outlet />
            </div>
        </div>
        
        </>
    );
}

export default Vacancies;