import React,{useState,useEffect} from 'react';
import { useNavigate,useLocation  } from 'react-router-dom';
import api from '../../api/Axiosapi';

function ContactDetails(props) {
    const navigate = useNavigate ();
    const loc = useLocation();
    const [name,setName] = useState('');
    const [designation,setDesignation]= useState('');
    const [phone,setPhone] = useState('');
    const [devision,setDevision] = useState('');
    const [email,setEmail]=useState('');
    const [instid,setInstid]=useState('');
    const [id,setId]=useState('');

    const token = sessionStorage.getItem('portalhr.token');
    const head = { 'Access-Control-Allow-Origin': '*','Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`};

    const saveRecord = event =>{
        var data = {
            "name": name,
            "designation": designation,
            "phone": phone,
            "devision": devision,
            "email": email,
            "instid" : instid,
            "id":id
        };
        try {
            switch (loc.state.mode) {
                case "NEW":
                    api.post("/contacts", data,{headers:head});
                    break;
                case "EDIT":
                    api.put("/contacts", data,{headers:head});
                    break;
                default:
                    break;
            }
            event.currentTarget.disabled = true;
            event.preventDefault();
        } catch (e) {
           
        }
        
        event.preventDefault();
    }

    useEffect(()=>{
        if(loc.state.mode ==="EDIT"){
            setName(loc.state.record.name);
            setDesignation(loc.state.record.designation);
            setPhone(loc.state.record.phone);
            setDevision(loc.state.record.devision);
            setEmail(loc.state.record.email);
            setInstid(loc.state.record.instid);
            setId(loc.state.record.id);
        }
        if(loc.state.mode ==="NEW"){
            setInstid(loc.state.instid);
        }
    },[])

    return (
        <>
          <h5 className="mt-2">Details</h5>
            <div className="row p-2">
                <div className="col-auto p-2">
                    <label className="visually-hidden" >Name</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-user"></i></div>
                        <input type="text" value={name} onChange={(e)=>setName(e.target.value)} className="form-control" placeholder="Name"></input>
                    </div>
                </div>
                <div className="col-auto p-2">
                    <label className="visually-hidden" >Designation</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-id-badge"></i></div>
                        <input type="url" value={designation} onChange={(e)=>setDesignation(e.target.value)} className="form-control" placeholder="Designation"></input>
                    </div>
                </div>
                <div className="col-auto p-2">
                    <label className="visually-hidden" >Contact No</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-phone"></i></div>
                        <input type="text" value={phone} onChange={(e)=>setPhone(e.target.value)} className="form-control" placeholder="Contact No"></input>
                    </div>
                </div>
                <div className="col-auto p-2">
                    <label className="visually-hidden" >Email</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-at"></i></div>
                        <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" placeholder="Email"></input>
                    </div>
                </div>
                <div className="col-auto p-2">
                    <label className="visually-hidden" >Department</label>
                    <div className="input-group">
                        <div className="input-group-text"><i className="fa fa-edit"></i></div>
                        <input type="text" value={devision} onChange={(e)=>setDevision(e.target.value)} className="form-control" placeholder="Department"></input>
                    </div>
                </div>
            </div>
            <div className="row p-2">
                <div className="col">
                    <button className="btn btn-sm m-2 btn-primary" onClick={saveRecord}>Save</button>
                    <button className="btn btn-sm m-2 btn-primary" onClick={()=>{navigate(-1)}}>Close</button>
                </div>
            </div>  
        </>
    );
}

export default ContactDetails;