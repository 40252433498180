import React,{useState,useMemo} from 'react';
import {BrowserRouter,Routes,Route,Link} from 'react-router-dom';

import App from './App';
import Login from './components/Login/Login';
import User from './components/User/User';
import Candidate from './components/Candidate/Candidate';
import CandidateList from './components/Candidate/CandidateList';
import CandidateFollowup from './components/Candidate/CandidateFollowup';
import Vacancies from './components/Client/Vacancies';
import Vacancies_lead from './components/Client/Vacancies_lead';
import VacanciesTabular from './components/Client/VacanciesTabular';
import Client from './components/Client/Client';
import ClientList from './components/Client/ClientList';
import Contacts from './components/Client/Contacts';
import ContactDetais from './components/Client/ContactDetails';
import InterviewList from './components/Interview/InterviewList';
import InterviewDetails from './components/Interview/InterviewDetails';
import InterviewCanAssign from './components/Interview/interviewCanAssign';
import SelectedCandidatesList from './components/Candidate/SelectedCandidatesList';
import ReminderList from './components/Reminder/ReminderList';
import Reminder from './components/Reminder/Reminder';
import ReminderListByCandidate from './components/Reminder/ReminderListByCandidate';
import Vacancy from './components/Client/Vacancy';
import VacancyAssignment from './components/User/VacancyAssignment';
import EmployeeList from './components/Employee/EmployeeList';
import Employee from './components/Employee/Employee';
import Tag from './components/Tag/Tag';
import EntityCandidates from './components/Tag/EntityCandidates';

import Managerhome from './pages/Managerhome';
import Manager_user from './pages/Manager_user';
import Leadhome from './pages/Leadhome';
import Supervisorhome from './pages/Supervisorhome';
import Supervisor_main from './pages/Supervisor_main';
import Frontdeskhome from './pages/Frontdeskhome';
import Frontdesk_candidates from './pages/Frontdesk_candidates';
import Frontdesk_main from './pages/Frontdesk_main';
import FrontdeskInstitutions from './pages/FrontdeskInstitutions';
import FrontdeskInterviews from './pages/FrontdeskInterviews';
import FrontdeskFollowups from './pages/FrontdeskFollowups';

import AuthContext from './context/AuthContext';
import Manager_main from './pages/Manager_main';
import Manager_vacancies from './pages/Manager_vacancies';

import Lead_vacancies from './pages/Lead_vacancies';
import Manager_selectedcandidates from './pages/Manager_selectedcandidates';
import CandidatesForVacancy from './components/Client/CandidatesForVacancy';

function Main(props) {
    const [user, setUser] = useState({"name":"defName","token":"defToken","role":"defRole"});
    //const value = useMemo(() => ({ user, setUser }), [user, setUser]);
    
    return (
        <AuthContext.Provider value={[user,setUser]}>
        <React.StrictMode>
            <BrowserRouter>
            <Routes>
                <Route path="/*" element={<App />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/manager" element={<Managerhome />}>
                    <Route path="" element={<Manager_main />}></Route>
                    <Route path="candidates" element={<Frontdesk_candidates />}>
                        <Route path="newCandidate" element={<Candidate />}></Route>
                        <Route path="search" element={<CandidateList />}>
                            <Route path="followup" element={<CandidateFollowup />}></Route>
                        </Route>
                    </Route>
                    <Route path="selcandidates" element={<Manager_selectedcandidates />}>
                        <Route path="search" element={<SelectedCandidatesList />}>
                            
                        </Route>
                    </Route>
                    <Route path="vacancies" element={<Manager_vacancies />}>
                        <Route path="search" element={<VacanciesTabular />}></Route>
                    </Route>

                    <Route path="users" element={<Manager_user />}>
                        <Route path="newUser" element={<User />}></Route>
                    </Route>

                </Route>
                <Route path="/lead" element={<Leadhome />}>
                    <Route path="" element={<Lead_vacancies />}>
                        <Route path="search" element={<VacanciesTabular />}>
                        <Route path="vacancyAssignment" element={<VacancyAssignment />}></Route>
                        </Route>
                        <Route path="newVacancy" element={<Vacancy />}></Route>
                    </Route>
                    <Route path="institutions" element={<FrontdeskInstitutions />}>
                        <Route path="searchClient" element={<ClientList />}>
                            <Route path="clientVacancies" element={<Vacancies_lead />}></Route>
                            <Route path="clientContacts" element={<Contacts />}>
                                <Route path="newContact" element={<ContactDetais />}></Route>
                            </Route>
                            <Route path="clientDetails" element={<Client />}></Route>
                        </Route>
                        <Route path="newClient" element={<Client />}></Route>
                    </Route>
                </Route>
                <Route path="/supervisor" element={<Supervisorhome />}>
                    <Route path="" element={<Supervisor_main />}>
                        <Route path="search" element={<EmployeeList />}>
                        <Route path="employeeDetails" element={<Employee />}></Route>
                        </Route>
                        <Route path="newEmployee" element={<Employee />}></Route>
                    </Route>
                </Route>
                <Route path="/frontdesk" element={<Frontdeskhome />}>
                    <Route path="" element={<Frontdesk_main />}></Route>
                    <Route path="candidates" element={<Frontdesk_candidates />}>
                        <Route path="newCandidate" element={<Candidate />}></Route>
                        <Route path="search" element={<CandidateList />}>
                            <Route path="followup" element={<Reminder />}></Route>
                            <Route path="tag" element={<Tag />}></Route>
                        </Route>
                        <Route path="tagentity_search_candidates" element={<EntityCandidates />}>
                            <Route path="followup" element={<Reminder />}></Route>
                            <Route path="tag" element={<Tag />}></Route>
                        </Route>
                    </Route>
                    <Route path="institutions" element={<FrontdeskInstitutions />}>
                        <Route path="searchClient" element={<ClientList />}>
                            <Route path="clientVacancies" element={<Vacancies />}>
                                <Route path="assigncaninterview" element={<InterviewCanAssign />}>
                                    <Route path="search" element={<CandidateList />}>
                                    </Route>
                                </Route>
                                <Route path="listCandidatesForVacancy" element={<CandidatesForVacancy />}>
                                    
                                </Route>
                            </Route>
                            <Route path="clientContacts" element={<Contacts />}>
                                <Route path="newContact" element={<ContactDetais />}></Route>
                            </Route>
                            <Route path="clientDetails" element={<Client />}></Route>
                        </Route>
                        <Route path="newClient" element={<Client />}></Route>
                    </Route>
                    <Route path="interviews" element={<FrontdeskInterviews />}>
                        <Route path="searchInterviews" element={<InterviewList />}>
                        <Route path="interviewDetails" element={<InterviewDetails />}></Route>
                        </Route>
                    </Route>
                    <Route path="followups" element={<FrontdeskFollowups />}>
                        <Route path="searchReminders" element={<ReminderList />}>
                        <Route path="followupByCandidate" element={<ReminderListByCandidate />}>
                            <Route path="followup" element={<CandidateFollowup />}/>
                        </Route>
                        </Route>
                    </Route>
                    
                </Route>
            </Routes>
            </BrowserRouter>
        </React.StrictMode>
        </AuthContext.Provider>
    );
}

export default Main;