import React from 'react';
import InterviewSearch from '../components/Interview/InterviewSearch';
import { Outlet } from 'react-router-dom';

function FrontdeskInterviews() {
    return (
        <>
            <h5>Interview Search</h5>
            <InterviewSearch />
            <br></br>
            <Outlet />
        </>
        
    )
}

export default FrontdeskInterviews;